import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import getIcon from '../../util/IconUtil';
import Icon from '@material-ui/core/Icon';

// styles
import { withStyles } from '@material-ui/core/styles';
import EventManager from '../../event/Event';
import { ListBase, events as baseEvents, triggers as baseTriggers } from '../List/ListBase'
export const events = baseEvents;
export const triggers = baseTriggers;

const style = theme => ({

});

export const view = {
  style: {
    buttonVariant: {},
    size: {},
    color: {}
  }
}



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    }
  },
}
));

function ButtonRegular(props) {
  const classes = useStyles();
  let data = props.data;
  const style = props.view.style;

  if (!data) { return; }

  function handleClick(e) { props.callback('click', data, e); }

  const link = data.link || {};

  if (data.title) {
    return (
      <Button
        key={data.id}
        variant={data.variant || style.buttonVariant}
        color={data.color || style.color}
        size={data.size || style.size}
        disabled={data.disabled || false}
        className={classes.button}
        href={link.url}
        target={link.target}
        startIcon={getIcon(data.icon)}
        // button events 
        onClick={handleClick}
      >
        {data.title}
      </Button>
    )
  } else {
    return (
      <IconButton
        key={data.id}
        variant={data.variant || style.buttonVariant}
        color={data.color || style.color}
        disabled={data.disabled || false}
        className={classes.button}
        href={link.url}
        target={link.target}
        alt={data.title}
        // button events 
        onClick={handleClick}
      >
        <Icon>{getIcon(data.icon)}</Icon>
      </IconButton>
    )
  }
}

function ButtonFab(props) {
  const classes = useStyles();
  const style = props.view.style;
  let data = props.data;
  if (!data) { return; } // if no button exists we return nothing

  let size = data.size || style.size;
  let variant = data.variant || style.buttonVariant || 'circular';

  function handleClick(e) { props.callback('click', data, e); /*e.preventDefault();*/ }

  if (data.title) { variant = "extended"; }
  if (size === 'large') { size = undefined; }
  return (
    <Fab
      variant={variant}
      color={data.color || style.color}
      disabled={data.disabled || false}
      size={size}
      // button events 
      onClick={handleClick}
    >
      {
        getIcon(data.icon, classes.extendedIcon)
      }
      {data.title}
    </Fab>
  )
}

function ButtonSingle(props) {
  if ((props.data.variant || props.view.style.buttonVariant) === "fab") {
    return ButtonFab(props)
  } else {
    return ButtonRegular(props)
  }
}

class ButtonComponent extends ListBase {

  constructor(props) {
    super(props);
    this.eventDD = this.registerComponent({}, {}, {
      name: "ButtonComponent",
      author: "Kjartan Jónsson",
      description: "Button component",
      version: "0.3.2"
    });
  }

  setSelectedId = (id) => {
    EventManager.getInstance().addEvent(this.props.id, this.eventDD['selected'].id, { id: id }, null);
  }

  render() {
    let content = [];

    const items = this.state.data;

    // style
    const { classes } = this.props;

    if (items.length === 1) {
      const itm = items[0];
      content.push(<ButtonSingle key={itm.id} data={itm} view={this.props.view} callback={(event, data, evt) => {
        EventManager.getInstance().addEvent(this.props.id, 'selected', data, evt)
      }} />)
    } else {
      if (this.props.view.style.variant === 'fab') { // special case if this is multiple fabs
        items.map(itm =>
          content.push(<ButtonFab key={itm.id} data={itm} view={this.props.view} callback={(event, data, evt) => {
            EventManager.getInstance().addEvent(this.props.id, 'selected', data, evt)
          }} />)
        );
      } else { // return regular button group
        const style = this.props.view.style;
        content.push(
          <ButtonGroup key={this.props.id} variant={style.x} color={style.color} size={style.size}>
            {
              items.map((itm) => {
                const disabled = itm.disabled || false;
                const variant = itm.variant || style.buttonVariant;
                const link = itm.link || {};
                let size = itm.size || style.size;
                if (size === 'large') { size = undefined; }
                if (itm.icon && (!itm.title)) {
                  return (
                    <IconButton
                      key={itm.id}
                      variant={variant}
                      color={itm.color || style.color}
                      disabled={disabled}
                      className={classes.button}
                      size={size}
                      alt={itm.title}
                      href={link.url}
                      target={link.target}
                      // button events 
                      onClick={(evt) => {
                        EventManager.getInstance().addEvent(this.props.id, 'selected', itm, evt)
                      }}
                    >
                      <Icon>{getIcon(itm.icon, 'small')}</Icon>
                    </IconButton>)
                } else {
                  return (
                    <Button
                      key={itm.id}
                      variant={variant}
                      color={itm.color || style.color}
                      disabled={disabled}
                      size={itm.size || style.size}
                      href={link.url}
                      target={link.target}
                      className={classes.button}
                      startIcon={getIcon(itm.icon)}
                      onClick={(evt) => {
                        EventManager.getInstance().addEvent(this.props.id, 'selected', itm, evt)
                      }} >{itm.title || itm.label}</Button>)
                }
              }
              )}
          </ButtonGroup>
        )
      }
    }

    return content;
  }
}

export default withStyles(style, { withTheme: true })(ButtonComponent);