import React from 'react';

import ListComponent from './ListComponent'
import DropdownComponent from './DropdownComponent'

// layout manager
import LayoutManager from '../Layout/Manager'

export function List(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<ListComponent {...props} />);
}

export function Dropdown(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<DropdownComponent {...props} />);
}

export function registerList() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: List,
        type: LayoutManager.TYPE.list
    });
}

export function registerDropdown() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Dropdown,
        type: LayoutManager.TYPE.dropdown
    });
}