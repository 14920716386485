import React from 'react';

import { ListBase, events as baseEvents, triggers as baseTriggers } from '../List/ListBase'
// layout
import LayoutManager from '../Layout/Manager';

export const triggers = baseTriggers;
export const events = baseEvents;

export class LayoutBase extends ListBase {

  // FIXME: fetch from utils
  renderContent = (classes, item) => {
    if (item.hidden) {
      return null;
    }
    let content = item.content;
    const content_type = typeof (content);
    if (content_type === 'object') {
      content = LayoutManager.getInstance().getComponentInstance(content.type, content || {});
    } else {
      if (content_type !== 'string') {
        content = "Expected object for id " + item.id;
      }
    }
    return <div>{content}</div>;
  }

}

