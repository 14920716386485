import React from 'react';
// Firebase.
import firebase from 'firebase/app';

import 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Button from '@material-ui/core/Button';
// Styles
import styles from './App.css'; // This uses CSS modules.
import './firebaseui-styling.global.css'; // Import globally.
// import {firebaseApp} from '../../App';

// event handler
import EventManager from '../../event/Event';


export const triggers = () => {
  return {
    access_scope: {
      alias: [],
      info: {
        name: 'Access Scope',
        description: 'Request access to scope more info'
      },
      schema: {}
    }
  }
}

export const events = () => {
  return {
    accessing_scope: {
      alias: [],
      info: {
        name: 'Requesting Access',
        description: 'Requesting access to more info'
      },
      schema: {}
    },
    accessed_scope: {
      alias: [],
      info: {
        name: 'Requested Access',
        description: 'Requested access result, null if no allowed'
      },
      schema: {}
    },
    scope_denied: {
      alias: [],
      info: {
        name: 'Scope Access denied by user',
        description: 'Scope Access was denied by user'
      },
      schema: {}
    },
    scope_failed: {
      alias: [],
      info: {
        name: 'Scope Access failed',
        description: 'Scope Access failed for some technical reason'
      },
      schema: {}
    },
    scope_canceled: {
      alias: [],
      info: {
        name: 'Scope Canceled',
        description: 'Scope request was canceled by user'
      },
      schema: {}
    }
  }
}


/**
 * The Splash Page containing the login UI.
 */
export class AppAuth extends React.Component {

  uiConfig = {
    signInFlow: 'redirect',
    signInOptions: [
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        return false;
      },
    },
  };

  state = {
    isSignedIn: undefined,
    user: undefined
  };


  constructor(props) {

    super(props);
    this.firebaseApp = props.firebaseApp;

    const component_info = {
      name: "Firebase Auth",
      author: "Kjartan Jónsson",
      description: "Firebase authentication",
      version: "0.3.2"
    }

    const dataActionHandlers = {
      access_scope: {
        schema: {},
        handler: (objs) => { // request for more access
          EventManager.getInstance().addEvent(this.props.id, 'accessing_scope', {}, {});
          this.requestScopeAccess();
        }
      },

      clear: {
        schema: {},
        handler: (obj) => {
          //EventManager.getInstance().addEvent(this.props.id, this.eventDD['clearing'].id, { count: this.state.data.length, items: this.state.data }, {});
        }
      }
    }

    // register componenet overiding or adding new event handlers
    this.events = EventManager.getInstance().register(this.props.id, dataActionHandlers, events(), component_info);

  }

  /**
   * @inheritDoc
   */
  componentDidMount() {
    this.firebaseApp.auth().onIdTokenChanged((user) => {
      if (user) {
        EventManager.getInstance().addEvent(this.props.id, 'idtoken', user.toJSON(), user);
      }
    });

    this.unregisterAuthObserver = this.firebaseApp.auth().onAuthStateChanged((user) => {

      let user_state = {}; // copy of user

      if (this.state.user) {
        user_state = this.state.user.toJSON();
      }

      // remember state
      this.setState({ isSignedIn: !!user, user: user });
      // send events
      if (user) {
        EventManager.getInstance().addEvent(this.props.id, 'authstate', user.toJSON(), user);
      } else {
        if ((Object.keys(user_state).length > 0) && (user === null)) { // detect if logging out
          EventManager.getInstance().addEvent(this.props.id, 'loggedout', user_state, {});
        }
        EventManager.getInstance().addEvent(this.props.id, 'unauthorised', user_state, {});
      }
    });
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  componentDidUpdate() {
    if (this.state.isSignedIn && this.state.user) {
      EventManager.getInstance().addEvent(this.props.id, 'loggedin', this.state.user.toJSON(), this.state.user);
    } else {
      if (this.firebaseApp.auth().currentUser) {
        EventManager.getInstance().addEvent(this.props.id, 'loggedin', this.state.user.toJSON(), this.state.user);
      }
    }
  }

  /**
   * @inheritDoc
   */
  render() {
    return (
      <div className={styles.container}>
        {this.state.isSignedIn !== undefined && !this.state.isSignedIn &&
          <div>
            <StyledFirebaseAuth className={styles.firebaseUi} uiConfig={this.uiConfig}
              firebaseAuth={this.firebaseApp.auth()} />
          </div>
        }
        {this.state.isSignedIn &&
          <div className={styles.signedIn}>
            <Button className={styles.button} onClick={() => this.firebaseApp.auth().signOut()}>Log out</Button>
          </div>
        }
      </div>
    );
  }

  requestScopeAccess(scope, display) {
    // TODO: use same provider that we got access to
    var provider = new firebase.auth.FacebookAuthProvider();
    // TODO: check that the provider we have access to also provied this scope
    provider.addScope('user_friends');
    // TODO: set the user experiance
    const access_method = 'popup';

    provider.setCustomParameters({
      'auth_type': 'rerequest',
      'display': access_method
    });
    if (access_method === 'popup') {
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          //  @type {firebase.auth.OAuthCredential}
          var credential = result.credential;

          // The signed-in user info.
          var user = result.user;

          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          var accessToken = credential.accessToken;
          // user.uid = 6dlEL7ETHqgpH6ZiOZiPdzDo90t1
          /// "https://graph.facebook.com/v8.0/me?fields=id%2Cname%2Cbirthday&access_token=EAALywTYjrlQBADUiq0UVPSeMgPv03dLLz3myRRl1lcs4RDHZCJvFK5n3yyFEEZCiy4wZCWrh0zzsyB10RqAuRafOx4kYJjZBAeH5sVHIYJRoul8ZCIPXZBjyyhUi5HgzKbEWHKllLSytMZAPZCJPCYreVJHaIUugfZCDn9wz1JCXCKP2Ew3UrfpYoRuM869lFLp5ZALYDhrSUcZCtkMiPam7NTAqsO6ckjByOMZD"
          // id,name,birthday,friends (we can add as we need)
          // https://graph.facebook.com/v8.0/me?fields=id%2Cname%2Cbirthday%2Cfriends&access_token=EAALywTYjrlQBADUiq0UVPSeMgPv03dLLz3myRRl1lcs4RDHZCJvFK5n3yyFEEZCiy4wZCWrh0zzsyB10RqAuRafOx4kYJjZBAeH5sVHIYJRoul8ZCIPXZBjyyhUi5HgzKbEWHKllLSytMZAPZCJPCYreVJHaIUugfZCDn9wz1JCXCKP2Ew3UrfpYoRuM869lFLp5ZALYDhrSUcZCtkMiPam7NTAqsO6ckjByOMZD
          // collect all relavant information and send event to user
          EventManager.getInstance().addEvent(this.props.id, 'accessed_scope', {
            user: user.toJSON(),
            credential: credential.toJSON(),
            access_token: accessToken
          }, {});

        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // TODO: check if the request was denied by the user, and send relavent info
          if (error.code === 'auth/popup-closed-by-user') {
            // collect all relavant information and send event to user
            EventManager.getInstance().addEvent(this.props.id, 'scope_canceled', {
              error_code: error.code,
              message: error.message,
              email: error.email,
              credential: error.credential
            }, {});
          }

          EventManager.getInstance().addEvent(this.props.id, 'scope_failed', {
            error_code: error.code,
            message: error.message,
            email: error.email,
            credential: error.credential
          }, {});

        });

    } else {
      //signInWithRedirect
    }
  }

}
