// https://material-ui.com/components/dialogs/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Popover from '@material-ui/core/Popover';

import Typography from '@material-ui/core/Typography';


import { LayoutRender } from '../Layout/Layout';

// event handler
import EventHandler from '../../event/Event';

export const triggers = () => {
  return {
    show: {
      alias: [],
      info: {
        name: 'Show',
        description: 'Show dialog'
      },
      schema: {}
    },
    close: {
      alias: [],
      info: {
        name: 'Close',
        description: 'Close dialog'
      },
      schema: {}
    }
  }
};

export const events = () => {
  return {
    showing: {
      alias: [],
      info: {
        name: 'Showing',
        description: 'Showing Dialog'
      },
      schema: {}
    },
    closed: {
      alias: [],
      info: {
        name: 'Closed',
        description: 'Closed Dialog'
      },
      schema: {}
    }
  }
};

export const view = {
  style: {
    modalType: {}
  }
};

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

function RenderPopover(props_id, data, classes, view, open, setOpen, body) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const divRef = React.useRef();

  const handleClick = (event) => {
    setAnchorEl(divRef.currentTarget);
    // setAnchorEl(anchorEl.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={props_id}
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 100, left: 100 }}
        // anchorEl={divRef} -- anchorEl
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>The content of the Popover.</Typography>
      </Popover>
    </div>
  );
}

export default function PopupComponent(props) {
  // events

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    EventHandler.getInstance().addEvent(props.id, 'showing', {}, {});
  };

  const handleClose = () => {
    setOpen(false);
    EventHandler.getInstance().addEvent(props.id, 'closed', {}, {});
  };

  // style
  const classes = useStyles();

  // register componenet
  EventHandler.getInstance().register(
    props.id, {
    'show': {
      schema: {},
      handler: function () {
        handleOpen();
      }
    },
    'close': {
      schema: {},
      handler: function () {
        handleClose();
      }
    }
  }
  );

  let body = null;
  // set defaults
  let view = props.view || {};
  view.style = props.view.style || {};
  // special case variants
  if (props.view.style.variant !== 'alert') {
    body = LayoutRender(props.id, props.data, classes, view, 'dialog')
  }
  return RenderPopover(props.id, props.data, classes, view, open, setOpen, body);
}
