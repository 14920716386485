// event handler
// TODO: create another version like this one -- https://github.com/dannyconnell/localbase 

import EventManager from '../../event/Event';
import { mergeDeep } from '../../util/ObjUtil';

export const triggers = () => {
    return {
        insert: {
            alias: [],
            info: {
                name: 'Insert',
                description: 'Insert object'
            },
            schema: {}
        },
        read: {
            alias: [],
            info: {
                name: 'Read',
                description: 'Read object'
            },
            schema: {}
        },
        update: {
            alias: [],
            info: {
                name: 'Update',
                description: 'Update object'
            },
            schema: {}
        },
        upsert: {
            alias: [],
            info: {
                name: 'Upsert',
                description: 'Upsert object'
            },
            schema: {}
        },
        delete: {
            alias: [],
            info: {
                name: 'Delete',
                description: 'Delete object'
            },
            schema: {}
        }
    }
}

export const events = () => {
    return {
        inserted: {
            alias: [],
            info: {
                name: 'inserted',
                description: 'Inserted item creates or replaces'
            },
            schema: {}
        }
    }
}



export default function ObjectCollection(collection_name, schema, data, options) {

    const deepMerge = (obj_target, obj_source) => {
        return { ...obj_target, ...obj_source }
    }

    const raiseSuccessEvent = (event_name, data, evt) => {
        EventManager.getInstance().addEvent(collection_name, event_name, data, evt);
    };

    const raiseFailureEvent = (event_name, data, evt) => {
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises
        EventManager.getInstance().addEvent(collection_name, event_name, data, evt);
    };

    const isValidDocumentSchema = (doc, schema) => {
        // returns true if doc validates against schema
        return true;
    }

    const isValidDocumentId = (document_id) => {
        return document_id !== null && document_id !== undefined;
    }

    const getValidDocumentId = (obj) => {
        const obj_id = obj.id || obj.identifier;
        if (isValidDocumentId(obj_id)) { return obj_id; }
        return null;
    }

    const setValidDocumentId = (doc_id, obj) => {
        obj.id = doc_id;
        return obj;
    }

    this.docs = data || {};

    // register componenet
    EventManager.getInstance().register(
        collection_name, {
        insert: {
            schema: {},
            handler: (obj) => {
                // https://firebase.google.com/docs/firestore/manage-data/add-data
                const schema_validation = isValidDocumentSchema(obj, schema);
                if (schema_validation) {
                    // notify event: creating - Pending
                    raiseSuccessEvent('inserting', obj);
                    // create the id
                    const document_id = getValidDocumentId(obj);
                    this.docs[document_id] = obj;
                    raiseSuccessEvent('inserted', obj);
                    // TODO: Event: created - Success
                    // TODO: Event: exists - Failure
                    // TODO: Event: failure - Failure
                } else {
                    // notify event: invalid - Failure validating against schema
                    raiseFailureEvent('invalid', obj, {
                        message: 'Document structure is invalid against schema',
                        code: 501,
                        data: { document: obj, schema: schema, reason: schema_validation }
                    });
                }
            }
        },
        read: {
            schema: {},
            handler: (document_id) => {
                // https://firebase.google.com/docs/firestore/query-data/get-data
                // validate document id
                if (!isValidDocumentId(document_id)) {
                    raiseFailureEvent('invalid', { id: document_id }, { message: 'Document identifier is invalid', code: 301, data: { document_id: document_id } });
                } else {
                    // notify event: reading - Pending
                    raiseSuccessEvent('reading', { id: document_id });
                    const obj = this.docs[document_id];
                    if (obj) {
                        // notify event: read - Success
                        try { raiseSuccessEvent('read', obj); }
                        catch (e) { raiseFailureEvent('failure', { id: document_id }, e); }
                    } else {
                        // notify event: missing - Failure
                        raiseFailureEvent('missing', { document_id: document_id }, { message: 'Document identifier is invalid', code: 401, data: { document_id: document_id } });
                    }
                }
            }
        },
        update: {
            schema: {},
            handler: (obj) => {
                // validate obj against schema
                const schema_validation = isValidDocumentSchema(obj, schema);
                if (schema_validation) {
                    // TODO: Event: invalidated - Failure validating against schema
                    // notify event: updating - Pending
                    let document_id = getValidDocumentId(obj);
                    raiseSuccessEvent('updating', obj);
                    if (document_id) {
                        const doc = this.docs[document_id];
                        if (doc) {
                            try {
                                this.docs[document_id] = mergeDeep(doc, obj);
                                raiseSuccessEvent('updated', this.docs[document_id]);
                            } catch (e) {
                                raiseFailureEvent('error', obj);
                            }
                        } else {
                            // notify event: missing - Failure
                            raiseFailureEvent('missing', { id: document_id, data: this.docs }, {});
                            // TODO: Event: failure - Failure
                        }
                    } else {
                        raiseFailureEvent('invalid', obj, { message: 'Document identifier is invalid', code: 301, data: document_id });
                    }
                } else {

                }
            }
        },
        upsert: {
            schema: {},
            handler: (obj) => {
                // validate document id
                const schema_validation = isValidDocumentSchema(obj, schema);
                if (schema_validation) {
                    //Event: upserting - Pending
                    raiseSuccessEvent('upserting', obj);
                    const document_id = getValidDocumentId(obj);
                    if (document_id) {
                        const doc = this.docs[document_id];
                        try {
                            this.docs[document_id] = deepMerge(doc, obj);
                            raiseSuccessEvent('upserted', this.docs[document_id]);
                        } catch (e) {

                            raiseFailureEvent('failure', obj, e);
                        }
                    }
                } else {
                    // notify event: invalid - Failure validating against schema
                    raiseFailureEvent('invalid', obj, {
                        message: 'Document structure is invalid against schema',
                        code: 501,
                        data: { document: obj, schema: schema, reason: schema_validation }
                    });
                }
            }
        },
        delete: {
            schema: {},
            handler: (document_id) => {
                // https://firebase.google.com/docs/firestore/manage-data/delete-data
                // validate document id
                document_id = getValidDocumentId(document_id);
                if (document_id) {
                    // Event: deleting - Pending
                    raiseSuccessEvent('deleting', { id: document_id });
                    const doc = this.docs[document_id];
                    // Do the actuall deletion
                    if (doc) { // TODO: check if this exist
                        delete this.docs[document_id];
                        raiseSuccessEvent('deleted', { id: document_id });
                        // Event: deleted - Success
                        // raiseSuccessEvent('deleted', obj);
                    } else {
                        raiseFailureEvent('missing', { id: document_id });
                    }
                } else {
                    raiseFailureEvent('invalid', { id: document_id }, { message: 'Document identifier is invalid', code: 301, data: { document_id: document_id } });
                }
            }
        }
    }
    );
}