import React from 'react';
// material ui components
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Tooltip from '@material-ui/core/Tooltip';
// styles
import { withStyles } from '@material-ui/core/styles';
// common tools
import getIcon from '../../util/IconUtil';
// event handler
import EventManager from '../../event/Event';


import { ListBase, events as baseEvents, triggers as baseTriggers } from '../List/ListBase'

export const events = baseEvents;
export const triggers = baseTriggers;
export const view = {
  style: {
    shape: {},
    max_count: {}
  }
};

const style = (theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  avatar: {
    backgroundColor: "lightgray",
  },
});

export function AvatarRender(props) {

  if (!props.data) { return null; }

  // merge items with view
  const onAvatarClick = props.onAvatarClick || null;
  let items = props.data || [];
  let view = props.view || {};

  if (!view.style) { view.style = {}; }

  if (items.length === 1) {
    return (
      <AvatarSingleRender name={items[0].id} key={items[0].id} data={items[0]} view={props.view} onClick={onAvatarClick} />
    )
  } else {
    return (
      <AvatarGroup max={view.style.max}>
        {items.map(itm =>
          (<AvatarSingleRender name={itm.id} key={itm.id} data={itm} view={view} onClick={onAvatarClick} />)
        )}
      </AvatarGroup>
    )
  }
}

export function AvatarSingleRender(props) {

  const classes = {}; //useStyles();
  let config = props.data;
  if (!config) { return null; } // if no avatar exists we return nothing
  let config_text = config.title;
  let style = props.view.style || {};

  if (config.image) {
    // return image with title
    if (config.title) {
      return (
        <Tooltip title={config.title}>
          <Avatar name={props.name} key={config.name} alt={config.title} variant={style.shape} className={classes.config} src={config.image} onClick={props.onClick} />
        </Tooltip>
      )
    } else {
      return (
        <Avatar name={props.name} key={config.name} alt={config.title} variant={style.shape} className={classes.config} src={config.image} />
      )
    }
  } else if (config.icon) {
    if (config.title) {
      return (
        <Tooltip title={config.title}>
          <Avatar name={props.name} key={config.name} alt={config.title} variant={style.shape} className={classes.avatar} onClick={props.onClick} >
            {getIcon(config.icon)}
          </Avatar>
        </Tooltip>
      )
    } else {
      return (
        <Avatar name={props.name} key={config.name} alt={config.title} variant={style.shape} className={classes.avatar} onClick={props.onClick} >
          {getIcon(config.icon)}
        </Avatar>
      )
    }
  } else {
    if (config_text.length > 1) { config_text = config_text.charAt(1).toLocaleUpperCase(); }
    return (
      <Tooltip title={config.title}>
        <Avatar name={config.name} key={config.name} alt={config.title} variant={style.shape} className={classes.avatar} onClick={props.onClick} >
          {config_text}
        </Avatar>
      </Tooltip>
    )
  }
}

class AvatarComponent extends ListBase {
  /**
   * Used to manage internal state of avatars
   */
  constructor(props) {

    super(props);
    this.eventDD = this.registerComponent({}, {}, {
      name: "AvatarComponent",
      author: "Kjartan Jónsson",
      description: "Avatar component",
      version: "0.3.2"
    });
  }

  getData = () => {
    return this.data;
  }

  onAvatarClick = (evt) => {
    let name = evt.target.parentElement.getAttribute("name");
    // click
    EventManager.getInstance().addEvent(name, this.ddEvent['selected'].id, this.props.data || {}, evt);
  }

  render() {
    // if (!this.props.data){ return null; }

    // merge items with view
    const onAvatarClick = this.props.onAvatarClick || this.onAvatarClick;
    let items = this.state.data || [];
    let view = this.props.view || {};

    if (!view.style) { view.style = {}; }
    if (!items) { return null; }
    if (items.length === 1) {
      return (
        <AvatarSingleRender name={items[0].id} key={items[0].id} data={items[0]} view={this.props.view} onClick={onAvatarClick} />
      )
    } else {
      if (!items.map) { return null; }
      if (!view.style) { return null; }

      return (
        <AvatarGroup max={view.style.max_count}>
          {items.map(itm =>
            (<AvatarSingleRender name={itm.id} key={itm.id} data={itm} view={view} onClick={onAvatarClick} />)
          )}
        </AvatarGroup>
      )
    }
  }

}

export default withStyles(style, { withTheme: true })(AvatarComponent);