import React from 'react';
// material ui components
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';// styles
import { withStyles } from '@material-ui/core/styles';
import getIcon from '../../util/IconUtil';

import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Favorite from '@material-ui/icons/Favorite';

// event handler
import EventManager from '../../event/Event';

import { ListBase, events as baseEvents, triggers as baseTriggers } from '../List/ListBase'

export const events = baseEvents;
export const triggers = baseTriggers;
export const view = {
  style: {
    iconIsSelected: {},
    iconUnSelected: {},
    size: {},
    color: {},
    direction: {},
    labelPlacement: {}
  }
};

const style = (theme) => ({
  root: {
    justifyContent: 'center'
  },
});

class CheckboxComponent extends ListBase {
  /**
   * Used to manage internal state of avatars
   */
  constructor(props) {
    super(props);
    this.eventDD = this.registerComponent({}, {}, {
      name: "CheckboxComponent",
      author: "Kjartan Jónsson",
      description: "Checkbox component",
      version: "0.3.2"
    });
  }

  handleSelect = (item, idx, evt) => {
    const state = this.state;
    state.data[idx].selected = evt.target.checked;
    this.setState(state);
    EventManager.getInstance().addEvent(this.props.id, this.eventDD['selected'].id, item, evt);
  };

  renderFormLabel = (legend_text) => {
    if (legend_text) {
      return (<FormLabel component="legend">{legend_text}</FormLabel>)
    }
  }

  renderFormHelperText = (helper_text) => {
    if (helper_text) {
      return (<FormHelperText>{helper_text}</FormHelperText>)
    }
  }

  render() {
    const { classes, view } = this.props;
    // defaults
    const style = { ...{ direction: 'column', size: undefined, color: "secondary", labelPlacement: "start", iconIsSelected: undefined, iconUnSelected: undefined }, ...view.style }
    let helper_text = '';
    const legend_text = '';
    const error = '';
    let iconIsSelected = undefined;
    let iconUnSelected = undefined;


    // TODO: use other pallet instead of this hack
    if (style.color.includes("text")) { style.color = style.color.replace("text", "").toLowerCase(); }

    // If icons are use lets look them up
    if (style.iconIsSelected) { iconIsSelected = getIcon(style.iconIsSelected); }
    if (style.iconUnSelected) { iconUnSelected = getIcon(style.iconUnSelected); }

    // should we display error text
    if (error) { helper_text = error; }

    return (
      <FormControl required error={error != ''} component="fieldset" className={classes.formControl}>

        {this.renderFormLabel(legend_text)}

        <FormGroup style={{ justifyContent: 'center' }} row={style.direction.includes('row')}>
          {this.state.data.map((itm, idx) =>
          (
            <FormControlLabel labelPlacement={style.labelPlacement} key={"form-control-label-" + itm.id}
              control={<Checkbox
                key={itm.id}
                onChange={(event) => this.handleSelect(itm, idx, event)} name={itm.id}
                color={style.color}
                size={style.size}
                disabled={itm.disabled || false}
                icon={iconUnSelected}
                checkedIcon={iconIsSelected}
              />
              }
              checked={itm.selected}
              label={itm.title}
            />
          )
          )}
        </FormGroup>
        {this.renderFormHelperText(helper_text)}
      </FormControl >
    )
  }
}

export default withStyles(style, { withTheme: true })(CheckboxComponent);
