import React from 'react';

import TextComponent from  './TextComponent';

// layout manager
import LayoutManager from '../Layout/Manager'

export function Text(props){
    // lets enumerate schema to extract uiSchema and validation
    return (<TextComponent {...props} />);
}

////////////////////////////////////////////////


export function registerText(){
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Text,
        type: LayoutManager.TYPE.text
    });
}
