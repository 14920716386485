// event handler
// TODO: create another version like this one -- https://github.com/dannyconnell/localbase 

import EventManager from '../../event/Event';
import { useLocation } from "react-router-dom";

export const triggers = () => {
    return {
        read: {
            alias: [],
            info: {
                name: 'Read',
                description: 'Read query parameters'
            },
            schema: {}
        }
    }
}

export const events = () => {
    return {
        read: {
            alias: [],
            info: {
                name: 'Read',
                description: 'Read query paramters'
            },
            schema: {}
        }
    }
}



export default function ViewParameter(collection_name, schema, data, options) {

    const raiseSuccessEvent = (event_name, data, evt) => {
        EventManager.getInstance().addEvent(collection_name, event_name, data, evt);
    };

    // register componenet
    EventManager.getInstance().register(
        collection_name, {
        read: {
            schema: {},
            handler: () => {
                const params = {};
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.forEach(function (value, key) {
                    if (params[key]) { // if exists, we need to change to array
                        if (Array.isArray(params[key])) {
                            params[key].push(value);
                        } else {
                            params[key] = [params[key]]; // save old value
                            params[key].push(value); // add the new one
                        }
                    } else {
                        params[key] = value;
                    }
                });
                raiseSuccessEvent("read", params, {})
            }
        }
    }
    );
}