// https://material-ui.com/components/autocomplete/
import React from 'react';
// material ui components
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// styles
import { withStyles } from '@material-ui/core/styles';
import { findItemIndexById } from '../../util/ObjUtil';
import EventManager from '../../event/Event';

import { ListBase, events as baseEvents, triggers as baseTriggers } from './ListBase'
export const events = baseEvents;
export const triggers = baseTriggers;


const style = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 160,
    backgroundColor: theme.palette.background.paper
  },
  list: undefined, //  --> results in styles reference warnings??
  media: undefined,
  expand: undefined,
  expandOpen: undefined,
  avatar: undefined
});

export const view = {
  style: {
    buttonVariant: {},
    label: {},
    color: {}
  }
}

class DropdownComponent extends ListBase {
  /**
   * Used to manage internal state of avatars
   */

  constructor(props) {
    if (!props.view.style) { props.view.style = { label: 'Dropdown', variant: 'outlined' } }
    super(props);

    this.eventDD = this.registerComponent({}, {}, {
      name: "DropdownComponent",
      author: "Kjartan Jónsson",
      description: "Dropdown component",
      version: "0.3.2"
    });

    this.user_typing = false;
  }

  handleEventOnChange = (evt, newValue, oldValue) => {
    if (newValue) {
      this.handleSelect(newValue, { id: newValue.id }, -1);
    }

  }

  setSelectedId = (id) => {
    const idx = findItemIndexById(id, this.state.data);
    this.setState({ selectedIndex: idx, selectedId: id, data: this.state.data });
    EventManager.getInstance().addEvent(this.props.id, this.eventDD['selected'].id, { id: id }, null);
  }

  getDefaultOption = () => {
    if (this.state.selectedId !== -1) {
      return this.state.data[this.state.selectedIndex];
    }
  }

  getOptionSelected = (opt, val) => {
    // FIXME: key to select
    if (this.state.data && opt) {
      if (this.state.selectedId === opt.id) {
        return this.state.data[this.state.selectedIndex];
      }
    }
    return null;
  }

  render() {
    // NOT USED const { classes } = this.props;
    return (
      <Autocomplete
        id={this.props.id}
        options={this.state.data || []}
        getOptionLabel={(option) => option.title}
        defaultValue={this.getDefaultOption} //{this.getOptionSelectedItem}
        // getOptionSelected={this.getOptionSelectedItem} // FIXME: key to select
        // getOptionDisabled={(option) => option === this.state.data[0] || option === this.state.data[2]}
        // style={{ width: 300 }}
        onChange={this.handleEventOnChange}
        onFocus={(evt) => { this.user_typing = true; }}
        onBlur={(evt) => { this.user_typing = false; }}
        renderInput={(params) => {
          if (this.user_typing === false) {
            const selected_item = this.getDefaultOption();
            if (selected_item) {
              params.inputProps.value = selected_item.title; // FIXME: trying to select dynamically. This is what I came up with
            }
          }
          return (<TextField {...params} label={this.props.view.style.label} variant={this.props.view.style.variant} />)
        }
        }
      />
    )
  }
}

export default withStyles(style, { withTheme: true })(DropdownComponent);

