import React from 'react';

import AvatarComponent from  './AvatarComponent'

// layout manager
import LayoutManager from '../Layout/Manager'

export default function Avatar(props){
    // lets enumerate schema to extract uiSchema and validation
    return <AvatarComponent {...props} />
    
}

export function register () {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Avatar,
        type: LayoutManager.TYPE.avatar
    });
}