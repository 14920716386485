// https://material-ui.com/components/autocomplete/
import React from 'react';
// material ui components
import Grid from '@material-ui/core/Grid';
// styles
import { withStyles } from '@material-ui/core/styles';

import { LayoutBase, events as baseEvents, triggers as baseTriggers } from './LayoutBase'
export const events = baseEvents;
export const triggers = baseTriggers;


const style = (theme) => ({
});

export const view = {
  style: {
    spacing: {},
    direction: {},
    justifyContent: {},
    alignItems: {},
    gridXS: {},
    gridSM: {}
  }
}

class GridComponent extends LayoutBase {
  /**
   * Used to manage internal state of avatars
   */

  constructor(props) {
    props.view.style = props.view.style || {};
    props.view.style = { ...{ spacing: 1, direction: 'column', justifyContent: 'center', alignItems: 'stretch', gridXS: 12, gridSM: 12 }, ...props.view.style }
    super(props);

    this.eventDD = this.registerComponent({}, {}, {
      name: "GridComponent",
      author: "Kjartan Jónsson",
      description: "Grid component",
      version: "0.3.2"
    });
  }

  renderGrid = (itm, idx, classes) => {
    if (!itm.hidden) {
      return (
        <Grid item key={itm.id} xs={itm.gridXS || this.props.view.style.gridXS || 12} sm={itm.gridSM || this.props.view.style.gridSM || 12}>
          {this.renderContent(classes, itm)}
        </Grid>
      )
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={this.props.view.style.spacing}
        direction={this.props.view.style.direction}
        justifyContent={this.props.view.style.justifyContent} // missmatch storybook vs app
        alignItems={this.props.view.style.alignItems}
      >
        {
          this.state.data.map((itm, idx) => { return this.renderGrid(itm, idx, classes); })}
      </Grid>
    )
  }
}

export default withStyles(style, { withTheme: true })(GridComponent);

export const schema = {
  "$id": "",
  "$schema": "https://json-schema.org/draft/2020-12/schema",
  description: "",
  type: "object",
  properties: {
    xs: {
    },
    sm: {
    },
    item: {
    }
  }
}