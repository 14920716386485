import React from 'react';

import DialogComponent from './DialogComponent';
import PopupComponent from './PopupComponent';

// layout manager
import LayoutManager from '../Layout/Manager'

export function Dialog(props) {
  // lets enumerate schema to extract uiSchema and validation
  return (<DialogComponent {...props} />);
}

export function Popup(props) {
  // lets enumerate schema to extract uiSchema and validation
  return (<PopupComponent {...props} />);
}

export function registerDialog() {
  // self register component to layout manager
  LayoutManager.getInstance().registerComponent({
    component: DialogComponent,
    type: LayoutManager.TYPE.dialog
  });
}

export function registerPopup() {
  // self register component to layout manager
  LayoutManager.getInstance().registerComponent({
    component: PopupComponent,
    type: LayoutManager.TYPE.popup
  });
}