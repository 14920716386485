// test data


////////////////////////////////////////////////////////////////////////////////////
/// friends

const dlg_list_manager_list = {
  data: [],
  id: 'dlg_list_manager_list',
  schema: {},
  type: 'list',
  view: {
    style: {}
  }
}

const dlg_list_manager_gift_add = {
  data: [
    {
    }
  ],
  id: 'dlg_list_manager_gift_add_item',
  schema: {},
  type: 'textfield',
  view: {
    style: { placeholder: "Create list" }
  }
}

const dlg_list_manager_btn_list_add = {
  data: [{
    'id': 'dlg_list_manager_btn_list_add',
    'disabled': false,
    'icon': 'font-awesome:fa fa-plus'
  }],
  id: 'dlg_list_manager_btn_list_add',
  schema: {},
  type: 'button',
  view: {
    style: { color: 'primary', buttonVariant: 'contained' }
  }
}

const dlg_list_manager_add_grid = {
  data: [{
    'gridXS': 9,
    'gridSM': 9,
    'id': 'dlg_list_manager_gift_add',
    'content': dlg_list_manager_gift_add
  }, {
    'gridXS': 3,
    'gridSM': 3,
    'id': 'dlg_list_manager_btn_list_add',
    'content': dlg_list_manager_btn_list_add
  }],
  id: 'DialogWithList_Grid',
  schema: {},
  type: 'grid',
  view: {
    style: {
      spacing: 1,
      direction: 'row',
      justifyContent: 'center',
      alignItems: 'stretch',
    }
  }
}

const DialogWithList_Grid = {
  data: [
    {
      'id': 'dlg_list_manager_gift_add',
      'content': dlg_list_manager_add_grid
    }, {
      'id': 'dlg_list_manager_list',
      'content': dlg_list_manager_list
    }
  ],
  id: 'dlg_gift_list_grid',
  schema: {},
  type: 'grid',
  view: {
    style: {
      spacing: 1,
      direction: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      gridXS: 12,
      gridSM: 12
    }
  }
}

const modal_list_dialog_content = {
  id: "dlg_gift_list_content",
  title: "Friends list",
  label: "Close",
  description: "Organizing all of your wishes allows you to share them with specific friends and family members. Bellow you can create new lists and share them with your Facebook friends. As well as view the lists that are shared by your friends.",
  content: DialogWithList_Grid
}

const modal_list_dialog = {
  id: 'dlg_gift_list',
  type: 'dialog',
  data: modal_list_dialog_content,
  view: {
    style: {}
  }
}
/////////////////////////////////////////////////////////////////////////////////////


const gifts = {
  id: 'gifts',
  type: 'card',
  data: [],
  view: {
    style: {}
  }
}

const button_add_gift = {
  id: 'btn_add_wish',
  type: 'button',
  view: { style: { buttonVariant: 'fab', size: 'large', color: 'primary' } },
  data: [{
    'id': 'id1',
    'icon': 'material-ui:Add'
  }]
}

const button_show_friends = {
  id: 'btn_show_friends',
  type: 'button',
  view: { style: { buttonVariant: 'text', size: 'large', color: 'primary' } },
  data: [{
    'id': 'id1',
    'icon': 'material-ui:Group'
  }]
}

const button_edit_list = {
  id: 'btn_edit_list',
  type: 'button',
  view: { style: { buttonVariant: 'text', size: 'small', color: 'primary' } },
  data: [{
    'id': 'id1',
    'icon': 'material-ui:List'
  }]
}

const button_auth_access = {
  id: 'btn_auth_access',
  type: 'button',
  view: { style: { buttonVariant: 'text', size: 'small', color: 'primary' } },
  data: [{
    'id': 'id1',
    //'icon': 'font-awesome:fa fa-facebook-official'
    'icon': 'material-ui:GroupAdd'
  }]
}

const modal_add_gift_content_text = {
  data: {
    value: ''
  },
  id: 'dlg_add_wish_text',
  schema: {},
  type: 'textfield',
  view: {
    style: {
      placeholder: 'What is your gift wish'
    }
  }
}

const modal_delete_gift_makesure = {
  id: 'dlg_delete_gift_makesure',
  type: 'dialog',
  view: { style: { modalType: 'confirm' } },
  data: {
    title: "Are you sure you want to delete",
    description: "Do you want to remove this gift?",
    label: "Remove"
  }
}

const modal_add_gift_content_dropdown = {
  data: [],
  id: 'select_list_dropdown',
  schema: {},
  type: 'dropdown',
  view: {
    style: {}
  }
}

const modal_add_wish_grid = {
  data: [{
    'id': 'modal_add_gift_content_dropdown',
    'content': modal_add_gift_content_dropdown
  }, {
    'id': 'modal_add_gift_content_text',
    'content': modal_add_gift_content_text
  }],
  id: 'dlg_add_wish_grid',
  schema: {},
  type: 'grid',
  view: {
    style: {
      spacing: 1,
      direction: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      gridXS: 12,
      gridSM: 12
    }
  }
}

const modal_add_gift = {
  id: 'dlg_add_wish',
  type: 'dialog',
  view: { style: { modalType: 'confirm', labelCancel: 'Cancel' } },
  data: {
    title: "Gift wish",
    description: "Enter an not or URL path to a website that will be scraped.",
    label: "Wish",
    content: modal_add_wish_grid
  }
}

///////////////////////////////////////////////// show friends list - starts

const modal_show_friends_update_friends = {
  data: [{
    id: 'header',
    text: 'Get list of friends using this app',
    typography: 'text'
  }],
  id: 'dlg_show_friends_update_friends_id',
  schema: {},
  type: 'text',
  view: {
    style: {}
  }
}

const modal_show_friends_content = {
  data: [],
  id: 'dlg_show_friends_list',
  schema: {},
  type: 'checkbox',
  view: {
    style: {}
  }
}

const modal_show_friends_fb_grid = {
  data: [{
    'id': 'modal_show_friends_auth_access_id',
    'content': button_auth_access,
    gridXS: 2,
    gridSM: 2
  }, {
    'id': 'modal_show_friends_update_friends_id',
    'content': modal_show_friends_update_friends,
    gridXS: 10,
    gridSM: 10
  }],
  id: 'dlg_show_friends_grid',
  schema: {},
  type: 'grid',
  view: {
    style: {
      spacing: 1,
      direction: 'row',
      // TODO: justifyContent: 'left',
      alignItems: 'center',
    }
  }
}

const modal_show_friends_grid = {
  data: [{
    'id': 'modal_show_friends_fb_id',
    'content': modal_show_friends_fb_grid
  }, {
    'id': 'modal_show_friends_content_id',
    'content': modal_show_friends_content
  }],
  id: 'dlg_show_friends_grid',
  schema: {},
  type: 'grid',
  view: {
    style: {
      spacing: 1,
      direction: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      gridXS: 12,
      gridSM: 12
    }
  }
}

const modal_show_friends = {
  id: 'dlg_show_friends',
  type: 'dialog',
  view: { style: { modalType: 'alert' } },
  data: {
    title: "Share your gift wishes",
    description: "Select the friends and family to share this gift wish list with",
    label: "Done",
    content: modal_show_friends_grid

  }
}


/////////////////////////////////////////////////// asdfasdf

const modal_list_edit_content_text = {
  data: {
    value: ''
  },
  id: 'dlg_edit_list_text',
  schema: {},
  type: 'textfield',
  view: {
    style: {
      placeholder: 'Name of the list'
    }
  }
}

const modal_edit_list = {
  id: 'dlg_edit_list',
  type: 'dialog',
  view: { style: { modalType: 'dialog' } },
  data: {
    title: "Edit list name",
    description: "Make the list name descriptive",
    label: "Done",
    content: modal_list_edit_content_text

  }
}

const modal_delete_list_makesure = {
  id: 'dlg_delete_makesure',
  type: 'dialog',
  view: { style: { modalType: 'confirm' } },
  data: {
    title: "Are you sure you want to delete",
    description: "Removing the list will remove all the wishes within it, here and with everyone you shared it with.",
    label: "Yes I´m sure"
  }
}

/////////////////////////////////////////////////
/**
    gifts,
    button_add_gift,
    // button_show_friends,
    button_edit_list,
    button_auth_access

 */

export const bottom_button_layout = {
  data: [{
    'id': 'button_add_gift_id',
    'content': button_add_gift
  }, {
    'id': 'gift_layout_list_id',
    'content': button_edit_list
  }, {
    'id': 'button_auth_access_id',
    'content': button_auth_access
  }],
  id: 'bottom_button_layout_id',
  schema: {},
  type: 'grid',
  view: {
    style: {
      'gridXS': 1,
      'gridSM': 1,
      spacing: 1,
      direction: 'row',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    }
  }
}

const gift_layout_welcome_layout_header = {
  data: [{
    id: 'header',
    text: 'Welcome',
    typography: 'text'
  }],
  id: 'gift_layout_welcome_layout_header_id',
  schema: {},
  type: 'text',
  view: {
    style: {}
  }
}

const gift_layout_welcome_layout = {
  data: [{
    'id': 'gift_layout_welcome_layout_header_id',
    'content': gift_layout_welcome_layout_header
  },],
  id: 'bottom_button_layout_id',
  schema: {},
  type: 'grid',
  view: {
    style: {
      'gridXS': 1,
      'gridSM': 1,
      spacing: 1,
      direction: 'row',
      // TODO: justifyContent: 'left',
      alignItems: 'stretch',
    }
  }
}


const gift_list_display_toggle = {
  id: 'gift_list_display_toggle',
  type: 'checkbox',
  data: [
  ],
  view: {
    style: {
      direction: 'row',
      color: 'primary',
      size: 'small',
      iconIsSelected: 'material-ui:Check',
      iconUnSelected: 'material-ui:CheckBoxOutlineBlank',
    }
  }
}

export const gift_layout = {
  data: [{
    'gridXS': 10,
    'gridSM': 10,
    'hidden': false,
    'id': 'gift_layout_welcome_layout_id',
    'content': gift_layout_welcome_layout
  }, {
    'gridXS': 10,
    'gridSM': 10,
    'hidden': false,
    'id': 'gift_list_display_toggle_layout_id',
    'content': gift_list_display_toggle
  }, {
    'gridXS': 11,
    'gridSM': 11,
    'id': 'gift_layout_list_id',
    'content': gifts
  }, {
    'gridXS': 9,
    'gridSM': 9,
    'id': 'gift_layout_add_gift_id',
    'hidden': true,
    'content': bottom_button_layout
  }],
  id: 'gift_layout_id',
  schema: {},
  type: 'grid',
  view: {
    style: {
      spacing: 1,
      direction: 'row',
      // TODO: justifyContent: 'center',
      alignItems: 'stretch',
    }
  }
}

///////////////////////////////////////////////// show friends list - ends

export const layout = {
  'root': [
    gift_layout
  ]
};

export const dialogs = [
  modal_add_gift,
  modal_show_friends,
  modal_edit_list,
  modal_list_dialog,
  modal_delete_list_makesure,
  modal_delete_gift_makesure
]

// "icon": "https://cdn4.iconfinder.com/data/icons/christmas-2344/50/4-512.png",
