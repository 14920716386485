// event handler
// TODO: create another version like this one -- https://github.com/dannyconnell/localbase 

import EventManager from '../../event/Event';
import { useLocation } from "react-router-dom";

export const triggers = () => {
    return {
        show: {
            alias: [],
            info: {
                name: 'show',
                description: 'Show page'
            },
            schema: {}
        },
        open: {
            alias: [],
            info: {
                name: 'Open',
                description: 'Open page in new window'
            },
            schema: {}
        }

    }
}

export const events = () => {
    return {
        showing: {
            alias: [],
            info: {
                name: 'Showing',
                description: 'Show page'
            },
            schema: {}
        },
        open: {
            alias: [],
            info: {
                name: 'Open',
                description: 'Open page in new window'
            },
            schema: {}
        }

    }
}



export default function ViewPage(collection_name, schema, data, options) {

    const raiseSuccessEvent = (event_name, data, evt) => {
        EventManager.getInstance().addEvent(collection_name, event_name, data, evt);
    };

    // register componenet
    EventManager.getInstance().register(
        collection_name, {
        show: {
            schema: {},
            handler: (obj) => {
                let url = obj.url;
                // if (url.indexOf("//") !== 0){ url = "//" }
                raiseSuccessEvent("showing", obj, {})
                if (!url) {
                    window.location.reload();
                } else {
                    window.location.href = url;
                }
            }
        },
        open: {
            schema: {},
            handler: (obj) => {
                let url = obj.url;
                raiseSuccessEvent("open", obj, {})
                if (!url) {
                    window.location.reload();
                } else {
                    window.open(url, obj.target || '_blank');
                }
            }
        }
    }
    );
}