import React from 'react';

import CmmnJS from 'cmmn-js/dist/cmmn-modeler.production.min.js'; // https://bpmn.io/toolkit/bpmn-js/walkthrough/
import { DDCMMNViewerComponent } from './CMMNViewer'
// editor requires CSS
import 'cmmn-js/dist/assets/diagram-js.css';
import 'cmmn-js/dist/assets/cmmn-font/css/cmmn.css';
// common code between BPMNEditor and BPMNViewer
import {
  commonEventsOMG
} from './OMGShared'
// event handler
import EventManager from '../../event/Event'

export class DDCMMNEditorComponent extends DDCMMNViewerComponent {

  constructor(config) {
    super(config);
    this.config = config;
    this.props = {};
    this.state = {};

    this.containerRef = React.createRef();
  }

  componentDidMount() {

    const {
      url,
      diagramXML
    } = this.props || { url: null, diagramXML: '' };

    const container = this.containerRef.current;

    this.getDefinitions = function () {
      return JSON.parse(JSON.stringify(this.cmmnEditor.getDefinitions()));
    }

    this.cmmnEditor = new CmmnJS({ container });

    // register componenet
    this.eventDD = EventManager.getInstance().register(
      this.config.id, {
      enable: {
        alias: [],
        info: {
          name: 'Enable',
          description: 'Allows user to interact with the diagram when selecting the diagram elemements'
        },
        schema: {},
        handler: (obj) => {
          console.info("TODO: enable ", obj);
        }
      },
      submit: (obj) => {
        const definition = this.getDefinitions();
        const self = this;
        this.cmmnEditor.saveXML({ format: true }, function (err, xml) {
          if (err) {
            return console.error('could not save CMMN 2.0 diagram', err);
          }

          const event_data = {
            xml: xml,
            definition: definition
          };
          EventManager.getInstance().addEvent(self.config.id, self.eventDD['submitted'].id, event_data, {});
        });

      },
      disable: (obj) => {
        console.info("TODO: Disable button")
      },
      load: (obj) => {
        this.setState({
          diagramXML: obj.xml,
          url: url
        });
        if (obj.xml) {
          this.loadDiagram(obj.xml);
        } else if (obj.url) {
          this.fetchDiagram(obj.url);
        }
      },
      select: (obj) => {
        console.info("TODO: select specified element")
      }
    }, commonEventsOMG, {
      name: 'BPMN Editor',
      version: '0.1.1',
      author: 'Kjartan Jonsson',
      organization: 'aGameCompany ehf',
      description: 'View Buisness Process Model Notation (BPMN) Diagrams as specified by the OMG group.'
    }
    );

    this.registerCommonEvenHandlers(this.cmmnEditor);

    if (url) {
      return this.fetchDiagram(url);
    } else if (diagramXML) {
      return this.loadDiagram(diagramXML);
    } else {
      // TODO: handle error that either rul or diagramXML must be defined
    }

    // TODO: share event - imported
  }

  componentWillUnmount() {
    this.cmmnEditor.destroy();
  }

  loadDiagram(diagramXML) {
    const self = this;
    // Share event - loading
    EventManager.getInstance().addEvent(this.config.id, this.eventDD['loading'].id, {
      xml: diagramXML
    }, {});

    // Do the import
    this.cmmnEditor.importXML(diagramXML, function (err) {
      if (!err) {
        self.cmmnEditor.get('canvas').zoom('fit-viewport');
        // Share event - loaded
        EventManager.getInstance().addEvent(self.config.id, self.eventDD['loaded'].id, {
          xml: diagramXML,
          definition: self.cmmnEditor.getDefinitions()
        }, {});
      } else {
        // TODO: better error handling
        // this.handleError(err)
      }
    });
  }

}
