import React from 'react';

import { DDBPMNViewerComponent } from './BPMNViewer'
import { DDBPMNEditorComponent } from './BPMNEditor'
// layout manager
import LayoutManager from '../../components/Layout/Manager'

export default function DDBPMNViewer(props) {
  // lets enumerate schema to extract uiSchema and validation
  return (<DDBPMNViewerComponent {...props} />);
}

export function DDBPMNEditor(props) {
  // lets enumerate schema to extract uiSchema and validation
  return (<DDBPMNEditorComponent {...props} />);
}

export function registerViewer() {
  // self register component to layout manager
  LayoutManager.getInstance().registerComponent({
    component: DDBPMNViewer,
    type: 'bpmn-viewer'
  });
}

export function registerEditor() {
  // self register component to layout manager
  LayoutManager.getInstance().registerComponent({
    component: DDBPMNEditor,
    type: 'bpmn-editor'
  });
}