// https://material-ui.com/components/dialogs/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';


import { LayoutRender } from '../Layout/Layout';
import { renderContent } from '../../util/ComponentUtil';
import Layouter from '../Layout/Layout';

// event handler
import EventHandler from '../../event/Event';

export const triggers = () => {
  return {
    show: {
      alias: [],
      info: {
        name: 'Show',
        description: 'Show dialog'
      },
      schema: {}
    },
    close: {
      alias: [],
      info: {
        name: 'Close',
        description: 'Close dialog'
      },
      schema: {}
    },
    confirmed: {
      alias: [],
      info: {
        name: 'Confirmed',
        description: 'User confirmed action'
      },
      schema: {}
    }
  }
};

export const events = () => {
  return {
    showing: {
      alias: [],
      info: {
        name: 'Showing',
        description: 'Showing Dialog'
      },
      schema: {}
    },
    closed: {
      alias: [],
      info: {
        name: 'Closed',
        description: 'Closed Dialog'
      },
      schema: {}
    }
  }
};

export const view = {
  style: {
    modalType: {}
  }
};

/*
const useStyles = makeStyles((theme) => (
  {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
*/



const modalStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitleClosable = withStyles(modalStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function DialogRender(id, data, classes, view, open, setOpen, modal_body) {
  if (!view.style) { view.style = {} }
  view.style.modalType = view.style.modalType || 'alert';
  view.style.labelCancel = view.style.labelCancel || 'Cancel'; // default cancel button label is cancel
  const handleClose = (opt) => {
    setOpen(false);
    EventHandler.getInstance().addEvent(id, 'closed', opt || {}, {});
    if (opt && opt.confirm) {
      EventHandler.getInstance().addEvent(id, 'confirmed', opt || {}, {});
    }
  };

  const handleDone = () => {
    handleClose();
  }

  if (view.style.modalType === 'modal') {

    // A Modal is a mode of a UI (commonly that of a Dialog Box) that makes the user's response a requirement. 
    // In other words, the user is required to choose an action and there is no option to ignore or dismiss the message.

    return (
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <DialogTitle>{data.title}</DialogTitle>
        <DialogContent>
          {modal_body}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDone} color="primary" autoFocus>
            {data.label}
          </Button>
        </DialogActions>
      </Dialog>
    );

    /*
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">Transition modal</h2>
          <p id="transition-modal-description">react-transition-group animates me.</p>
          {modal_body}
        </div>
      </Fade>
    </Modal>
  );
*/
  } else if (view.style.modalType === 'dialog') {
    // A Dialog or Dialog Box is a type of UI that speaks to the user and requests for the user's response. 
    // User could opt to ignore or dismiss the message.
    const DialogTitle = DialogTitleClosable;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <DialogTitle>{data.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data.description}
          </DialogContentText>

          {modal_body}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { handleClose({ 'confirm': true }) }} color="primary" autoFocus>
            {data.label}
          </Button>
        </DialogActions>
      </Dialog >
    );
  } else if (view.style.modalType === 'confirm') {
    // A Dialog box with yes or no option 
    // User could opt to ignore or dismiss the message.
    const DialogTitle = DialogTitleClosable;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <DialogTitle>{data.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data.description}
          </DialogContentText>
          {modal_body}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { handleClose({ 'confirm': false }) }} color="secondary">
            {view.style.labelCancel}
          </Button>
          <Button onClick={() => { handleClose({ 'confirm': true }) }} color="primary" autoFocus>
            {data.label}
          </Button>
        </DialogActions>
      </Dialog>
    );

  } else {
    // Alerts only contains text and and dismiss button
    const DialogTitle = DialogTitleClosable;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{data.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data.description}
          </DialogContentText>
          {modal_body}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {data.label}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


export default function DialogComponent(props) {
  // events

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setTimeout(function () {
      EventHandler.getInstance().addEvent(props.id, 'showing', {}, {});
    }, 100); // add delay since dialog content is not ready

  };
  const handleClose = () => {
    setOpen(false);
    EventHandler.getInstance().addEvent(props.id, 'closed', {}, {});
  };

  // style
  const classes = {}; //useStyles();
  // register componenet
  EventHandler.getInstance().register(
    props.id, {
    'show': {
      schema: {},
      handler: (obj) => {
        handleOpen();
      }
    },
    'close': {
      schema: {},
      handler: (obj) => {
        handleClose();
      }
    }
  }
  );

  let body = null;
  // set defaults
  let view = props.view || {};
  view.style = props.view.style || {};
  // special case variants

  if (props.data.content) {
    body = renderContent(classes, props.data);
  }
  return DialogRender(props.id, props.data, classes, view, open, setOpen, body);
}
