// https://material-ui.com/components/autocomplete/
import React from 'react';
// material ui components
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
// 
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// styles
import { withStyles } from '@material-ui/core/styles';

import {LayoutBase, events as baseEvents, triggers as baseTriggers} from './LayoutBase'
export const events = baseEvents;
export const triggers = baseTriggers;


const style = (theme) => ({
});

export const view = {
  style:{
  }
}

class AccordionComponent extends LayoutBase {
  /**
   * Used to manage internal state of avatars
   */

  constructor(props) {
    props.view.style = {...{}, ...props.view.style}
    super(props);

    this.eventDD = this.registerComponent({}, {}, {
      name: "AccordionComponent",
      author: "Kjartan Jónsson",
      description: "Accordion component",
      version: "0.3.2"
    });

  }

  handleChange(itm_id, idx){
    /* TODO
    const items = this.state.data;
    
    // if only one open at once
    for (let item_idx in items){
      items[item_idx].expanded = false;
    }
    */
    // toggle
    // items[idx].expanded = !items[idx].expanded; // toggle mode
    //this.setState({...this.state, ...{data: items}})
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.data.map((itm, idx) =>
          (
          <Accordion key={itm.id + idx} disabled={itm.disabled || false} onChange={this.handleChange(itm.id, idx)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>{itm.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>{this.renderContent(classes, itm)}</div>
            </AccordionDetails>
          </Accordion>
          )
        )}
      </div>
    )
  }  
}

export default withStyles(style, { withTheme: true })(AccordionComponent);

export const schema = {
  "$id": "",
  "$schema": "https://json-schema.org/draft/2020-12/schema",
  description: "",
  type: "object",
  properties: {
    disabled: {
    },
    title: {
    },
    description: {
    }
  }
}