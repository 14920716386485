import React, { Component } from 'react';
// material-ui
import TextField from '@material-ui/core/TextField'; // https://material-ui.com/components/text-fields/
// styles
import { withStyles } from '@material-ui/core/styles';
// event handler
import EventManager from '../../event/Event';

export const events = () => {
  return {
    changed: {
      alias: [],
      info: {
        name: 'Changed',
        description: 'Input value changed'
      },
      schema: {}
    }
  }
}

export const triggers = () => {
  return {
    submit: { info: { name: 'Submit', description: 'Submits the form data' }, schema: {}, alias: [] },
    enable: { info: { name: 'Enables', description: 'Enables the form so that we can change form inputs' }, schema: {}, alias: [] },
    disable: { info: { name: 'Disable', description: 'Disables the form so that we can not change input value' }, schema: {}, alias: [] },
    clear: { info: { name: 'Clears', description: 'Removes all input values clearing the form' }, schema: {}, alias: [] },
    populate: { info: { name: 'Populate', description: 'Fillls the form with specified data' }, schema: {}, alias: [] }
  }
};

export const view = {
  style: {
  }
};


const style = (theme) => ({ // https://material-ui.com/styles/basics/
});

class TextfieldComponent extends Component {
  /**
   * Used to manage internal state of avatars
   */
  constructor(props) {
    super(props);

    const component_info = {
      name: "TextfieldComponent",
      author: "Kjartan Jónsson",
      description: "Text input component",
      version: "0.3.2"
    };

    this.input_ref = null;
    // register componenet overiding or adding new event handlers
    this.ddEvent = EventManager.getInstance().register(this.props.id, {
      submit: {
        schema: {},
        handler: (obj) => {
          if (this.input_ref) {
            EventManager.getInstance().addEvent(this.props.id, 'submitted', { value: this.input_ref.value }, null);
          } else {
            console.error("Error: Form submit before successfull initialization");
          }
        }
      },
      enable: {
        schema: {},
        handler: (obj) => {
          if (this.input_ref) {
            this.input_ref.disabled = false;
            EventManager.getInstance().addEvent(this.props.id, 'disabled', { disabled: this.input_ref.disabled }, null);
          } else {
            console.error("Error: Form enable before successfull initialization");
          }
        }
      },
      disable: {
        schema: {},
        handler: (obj) => {
          if (this.input_ref) {
            this.input_ref.disabled = true;
            EventManager.getInstance().addEvent(this.props.id, 'enabled', { disabled: this.input_ref.disabled }, null);
          } else {
            console.error("Error: Form disable before successfull initialization");
          }
        }
      },
      clear: {
        schema: {},
        handler: (obj) => {
          if (this.input_ref) {
            this.input_ref.value = "";
            EventManager.getInstance().addEvent(this.props.id, 'cleared', {}, null);
            EventManager.getInstance().addEvent(this.props.id, 'changed', { value: obj.value }, null);
          } else {
            console.error("Error: Form enable before successfull initialization");
          }
        }
      },
      populate: {
        schema: {},
        handler: (obj) => {
          if (this.input_ref) {
            this.input_ref.value = obj.value;
            EventManager.getInstance().addEvent(this.props.id, 'populated', { value: obj.value }, null);
            EventManager.getInstance().addEvent(this.props.id, 'changed', { value: obj.value }, null);
          }
        }
      }
    }, {}, component_info);

  }

  getUISchema = (schema) => {
    // enumerates schema and extracts uiSchema from ui-schema keywords
    // TODO: make this recursive for sub propertis
    const propAttributes = ["enumNames", "minLength", "maxLength"];
    let uiSchema = {}
    if (!schema) { schema = {}; }
    if (!schema.properties) { schema.properties = {}; }
    for (let prop in schema.properties) {
      for (let attr in schema.properties[prop]) {
        if (attr === "edit") { // rename this attribute to ui:options
          uiSchema[prop] = {};
          for (let p in schema.properties[prop][attr]) {
            uiSchema[prop]["ui:" + p] = schema.properties[prop][attr][p];
          }
          for (let i in propAttributes) {
            let move_attr = propAttributes[i];
            if (schema.properties[prop][attr][move_attr]) {
              schema.properties[prop][move_attr] = schema.properties[prop][attr][move_attr];
            }
          }

        }
      }
    }
    return uiSchema;
  }


  onChanged = (evt) => {
    // schema: formatData (only if valid)
    EventManager.getInstance().addEvent(this.props.id, "changed", { value: evt.target.value }, evt);
  }

  onKeyUp = (evt) => {
    if (evt.key === "Enter") {
      EventManager.getInstance().addEvent(this.props.id, "submitted", { value: evt.target.value }, evt);
    }
  }

  render() {

    // additional configuration options
    let configProps = {};

    // submit button 
    const hide_submit = true;
    if (hide_submit) { configProps.children = <div></div>; }

    // do not show error list
    configProps.howErrorList = false;
    configProps.ErrorList = this.OnInvalid;

    // forward custom uiSchema if defined
    if (this.props.uiSchema) {
      configProps.uiSchema = this.props.uiSchema;
    } else {
      configProps.uiSchema = this.getUISchema(this.props.schema);
    }

    return (<TextField
      id={this.props.id}
      onChange={this.onChanged}
      onKeyUp={this.onKeyUp}
      type="text"
      fullWidth={true}
      placeholder={this.props.view.style.placeholder}
      inputRef={instance => { this.input_ref = instance; }}
    />)
  }

}

export default withStyles(style, { withTheme: true })(TextfieldComponent);