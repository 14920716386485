import React, { Component } from 'react';

import BpmnJS from 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js'; // https://bpmn.io/toolkit/bpmn-js/walkthrough/
//import BpmnJS from 'bpmn-js/dist/bpmn-modeler.production.min.js';

import {
  elementSelectBPMN,
  commonEventsOMG
} from './OMGShared'

// event handler
import EventManager from '../../event/Event'

export class DDBPMNViewerComponent extends Component {

  constructor(props) {
    super(props);
    this.config = props;
    this.props = {};
    this.state = {};
    this.containerRef = React.createRef();
  }

  elementSelect(dd_event, self, event, detailed) {
    // TODO: share event - select
    const elementIncoming = [];
    const elementOutgoing = [];
    const buisinessEvent = event.element.businessObject;

    if (!buisinessEvent) {
      // TODO: react to error
      return;
    }

    let event_info = {
      element: {
        bpmnId: buisinessEvent.id,
        bpmnType: buisinessEvent['$type'],
        label: buisinessEvent.name
      }
    }

    if (detailed) {
      if (buisinessEvent.incoming) {
        buisinessEvent.incoming.forEach((item, idx) => {
          elementIncoming.push({
            bpmnId: item.id,
            bpmnType: item['$type']
          });
        });
      }

      if (buisinessEvent.outgoing) {
        buisinessEvent.outgoing.forEach((item, idx) => {
          elementOutgoing.push({
            bpmnId: item.id,
            bpmnType: item['$type']
          });
        });
      }

      event_info.incoming = elementIncoming;
      event_info.outgoing = elementOutgoing;
      event_info.definition = this.bpmnViewer.getDefinitions();
      event_info.parent = null;

      if (event.element.parent) {
        event_info.parent = {
          bpmnId: event.element.parent.id,
          bpmnType: event.element.parent['type'] || event.element.parent['$type']
        }
      }
    }

    EventManager.getInstance().addEvent(this.config.id, dd_event, event_info, self);
  }

  componentDidMount() {

    const {
      url,
      diagramXML
    } = this.props || { url: null, diagramXML: '' };

    const container = this.containerRef.current;

    this.bpmnViewer = new BpmnJS({ container });

    // register componenet
    this.eventDD = EventManager.getInstance().register(
      this.config.id, {
      enable: {
        alias: [],
        info: {
          name: 'Enable',
          description: 'Allows user to interact with the diagram when selecting the diagram elemements'
        },
        schema: {},
        handler: (obj) => {
          console.info("TODO: enable ", obj);
        }
      },
      disable: {
        alias: [],
        info: {
          name: 'Enable',
          description: 'Allows user to interact with the diagram when selecting the diagram elemements'
        },
        schema: {},
        handler: (obj) => {
          console.info("TODO: Disable button");
        }
      },
      submit: {
        alias: [],
        info: {
          name: 'Enable',
          description: 'Allows user to interact with the diagram when selecting the diagram elemements'
        },
        schema: {},
        handler: (obj) => {
          console.info("TODO: send data");
          // elementSelectBPMN('submitted', this, event, false, this);
        }
      },
      load: {
        alias: [],
        info: {
          name: 'Enable',
          description: 'Allows user to interact with the diagram when selecting the diagram elemements'
        },
        schema: {},
        handler: (obj) => {
          this.setState({
            diagramXML: obj.xml,
            url: url
          });

          if (obj.xml) {
            this.loadDiagram(obj.xml);
          } else if (obj.url) {
            this.fetchDiagram(obj.url);
          }
        }
      },
      select: {
        alias: [],
        info: {
          name: 'Enable',
          description: 'Allows user to interact with the diagram when selecting the diagram elemements'
        },
        schema: {},
        handler: (obj) => {
          console.info("TODO: select specified element")
        }
      }
    }, commonEventsOMG, {
      name: 'BPMN Viewer',
      version: '0.1.1',
      author: 'Kjartan Jonsson',
      organization: 'aGameCompany ehf',
      description: 'View Buisness Process Model Notation (BPMN) Diagrams as specified by the OMG group.'
    }
    );

    this.registerCommonEvenHandlers(this.bpmnViewer);

    if (url) {
      return this.fetchDiagram(url);
    }

    if (diagramXML) {
      return this.loadDiagram(diagramXML);
    }

    // TODO: share event - imported
  }

  registerCommonEvenHandlers(bpmnInstance) {

    bpmnInstance.on('element.changed', (event) => {
      // TODO: share event - edited
      const data = {};
      EventManager.getInstance().addEvent(this.config.id, this.eventDD['edited'].id, data, event);
    });

    bpmnInstance.on('element.hover', (event, detailEvent) => { elementSelectBPMN(this.eventDD['hover-over'].id, this, event, false, bpmnInstance); });
    bpmnInstance.on('element.out', (event, detailEvent) => { elementSelectBPMN(this.eventDD['hover-exit'].id, this, event, false, bpmnInstance); });
    bpmnInstance.on('element.click', (event, detailEvent) => { elementSelectBPMN(this.eventDD['selected'].id, this, event, true, bpmnInstance); });
    bpmnInstance.on('element.dblclick', (event, detailEvent) => { elementSelectBPMN(this.eventDD['tap'].id, this, event, true, bpmnInstance); });
    bpmnInstance.on('element.mousedown', (event, detailEvent) => { elementSelectBPMN(this.eventDD['press'].id, this, event, false, bpmnInstance); });
    bpmnInstance.on('element.mouseup', (event, detailEvent) => { elementSelectBPMN(this.eventDD['release'].id, this, event, false, bpmnInstance); });

    bpmnInstance.on('import.done', (event, detailEvent) => {
      const {
        error,
        warnings
      } = event;

      if (error) {
        // TODO: share event - import-error
        return this.handleError(error);
      }

      bpmnInstance.get('canvas').zoom('fit-viewport');

      return this.handleShown(warnings);
    });
  }

  componentWillUnmount() {
    this.bpmnViewer.destroy();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      props,
      state
    } = this;

    if (props.url !== prevProps.url) {
      return this.fetchDiagram(props.url);
    }

    const currentXML = props.diagramXML || state.diagramXML;

    const previousXML = prevProps.diagramXML || prevState.diagramXML;

    if (currentXML && currentXML !== previousXML) {
      return this.loadDiagram(currentXML);
    }
  }

  loadDiagram(diagramXML) {
    const self = this;
    // Share event - loading
    EventManager.getInstance().addEvent(this.config.id, this.eventDD['loading'].id, {
      xml: diagramXML
    }, {});

    // Do the import
    this.bpmnViewer.importXML(diagramXML).then(function (result) {
      // Share event - loaded
      EventManager.getInstance().addEvent(self.config.id, self.eventDD['loaded'].id, {
        xml: diagramXML,
        definition: self.bpmnViewer.getDefinitions(),
        warnings: result.warnings
      }, {});
    }).catch(function (err, a, b) {
      // TODO: better error handling
      self.handleError(err);
    });
  }

  fetchDiagram(url) {
    this.handleLoading();
    fetch(url)
      .then(response => response.text())
      .then(text => this.setState({ diagramXML: text }))
      .catch(err => this.handleError(err));
  }

  handleLoading() {
    const { onLoading } = this.props;

    if (onLoading) {
      onLoading();
    }
  }

  handleError(err) {
    // TODO: refactor following to this -> this.eventDD('failure', err);
    EventManager.getInstance().addEvent(this.config.id, this.eventDD['failure'].id, {
      code: 12012,
      detail: 'asdf'
    }, err);
  }

  handleShown(warnings) {
    const { onShown } = this.props;

    if (onShown) {
      onShown(warnings);
    }
  }

  render() {
    return (
      <div className="react-bpmn-diagram-container" ref={this.containerRef} style={{ flex: 1, height: 1000 }} ></div>
    );
  }
}
