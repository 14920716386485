
export default class LayoutManager {

    static _instance = null;

    _components = {};

    static TYPE = {
        avatar: 'avatar',
        button: 'button',
        card: 'card',
        form: 'form',
        dialog: 'dialog',
        popup: 'popup',
        image: 'image',
        graph: 'graph',
        menu: 'menu',
        layout: 'layout',
        list: 'list',  // some of many
        dropdown: 'dropdown',  // one of many
        table: 'table',
        text: 'text',
        grid: 'grid',
        page: 'page',
        container: 'container',
        appbar: 'appbar',
        textfield: 'textfield', // input
        checkbox: 'checkbox'
    };

    /**
     * @returns {EventManager}
     */
    static getInstance() {
        if (LayoutManager._instance === null) {
            LayoutManager._instance = new LayoutManager();
        }

        return this._instance;
    }

    clearAll() {
        this._components = {};
    }

    registerComponent(component) {
        /**
         * Adds component to layout manager of any type.
         * Specific types in TYPES are specifically used when automatically generating the layout using AI.
         * Expecting structure:
         *   - type
         *   - component
         */
        if (typeof (component.component) === "function") {
            this._components[component.type] = component;
        } else {
            console.error("Could not register " + component.type + " since it was not a function");
        }
    }

    getComponent(component_type) {
        return this._components[component_type]
    }

    getComponentInstance(component_type, parameters) {
        // validate parameter inputs
        const c = this._components[component_type];
        if (c) { // if React component is of type class
            if (c.is_withclass) {
                return c.component;
            }
            else { // if React component is of type function
                return new c.component(parameters);
            }
        } else {
            console.warn("Component instance not registered of type: " + component_type);
        }
    }
}