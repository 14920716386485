import React from 'react';

import TableComponent from './TableComponent'

// layout manager
import LayoutManager from '../Layout/Manager'

export function Table(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<TableComponent {...props} />);
}

export function register() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Table,
        type: LayoutManager.TYPE.table
    });
}
