// Firebase Config
export const config_firebase = {
  apiKey: "AIzaSyDsE5l25LmWVikb17UIV1VRL5ZzfWhiTTA",
  authDomain: "gift-wishes-c16c5.firebaseapp.com",
  projectId: "gift-wishes-c16c5",
  storageBucket: "gift-wishes-c16c5.appspot.com",
  messagingSenderId: "455332993558",
  appId: "1:455332993558:web:5b621e67d4f974af047337",
  measurementId: "G-R71FZSHBP8"
};


export const gift_wishes = {
  // add https://gjafaland.is/is/2842--lego-harry-potter-hogwars-lestin-75955.html
  "Noi-sirius-Konfekt-Pralin": {
    "id": "Noi-sirius-Konfekt-Pralin",
    "title": "Nói Siríus Konfekt Pralín Krem molar",
    "image": "https://static.heimkaup.is/images/products/58/8358/8358-1-noi-sirius-konfekt-krem-molar_670x400.jpg",
    "references": [
      {
        "id": "sdfgadf#$333345",
        "domain": "https://www.heimkaup.is",
        "url": "https://www.heimkaup.is/noi-sirius-konfekt-krem-molar?vid=10617",
        "title": "Nói Siríus Konfekt Pralín Krem molar",
        "summary": "Innihald: Mjólkursúkkulaði (sykur, kakósmjör, kakómassi, nýmjólkurduft, undanrennuduft, ýruefni ...",
        "price": { "price": 19.99, "currency": "usd" },
        "shipping": { "price": 43.81, "currency": "usd" },
        "delivery": { "hours-min": 200, "lead": 0 },
        "stock": {},
        "meta-og": {
          "og:url": "https://www.heimkaup.is/noi-sirius-konfekt-krem-molar?vid=10617",
          "og:title": "Nói Siríus Konfekt Pralín Krem molar",
          "og:description": "Innihald: Mjólkursúkkulaði (sykur, kakósmjör, kakómassi, nýmjólkurduft, undanrennuduft, ýruefni ...",
          "og:image": "https://www.heimkaup.is/noi-sirius-konfekt-krem-molar?vid=10617",
          "og:type": "product"
        }
      }
    ]
  },
  "Lara-fer-i-leikhus": {
    "id": "Lara-fer-i-leikhus",
    "title": "Lára fer í leikhús",
    "image": "https://static.heimkaup.is/images/products/53/113253/0add4-lara-fer-i-leikhus_670x400.jpg",
    "references": [
      {
        "id": '324sfdasdf23r42r6t675',
        "domain": "https://www.heimkaup.is",
        "url": "https://www.heimkaup.is/lara-fer-i-leikhus?vid=230674",
        "title": "Lára fer í leikhús",
        "summary": "Hin lífsglaða Lára hefur sérstaklega gaman af að læra eitthvað nýtt. Bangsinn Ljónsi fylgir henni...",
        "price": { "price": 19.99, "currency": "usd" },
        "shipping": { "price": 43.81, "currency": "usd" },
        "delivery": { "hours-min": 200, "lead": 0 },
        "stock": {},
        "meta-og": {
          "og:url": "https://www.heimkaup.is/friends-safabill?vid=163276",
          "og:title": "Lego Friends safabíll",
          "og:description": "Hin lífsglaða Lára hefur sérstaklega gaman af að læra eitthvað nýtt. Bangsinn Ljónsi fylgir henni...",
          "og:image": "https://static.heimkaup.is/images/products/53/113253/0add4-lara-fer-i-leikhus_670x400.jpg",
          "og:type": "product"
        }
      }
    ]
  },
  "Lego-Friends-safabill": {
    "id": "Lego-Friends-safabill",
    "title": "Lego Friends safabill",
    "image": "https://static.heimkaup.is/images/products/75/102475/3540e-lego-friends-safabill_670x400.jpg",
    "references": [
      {
        "id": '324sfdasdf23r42r6t675',
        "domain": "https://www.heimkaup.is",
        "url": "https://www.heimkaup.is/friends-safabill?vid=163276",
        "title": "Lego Friends safabill",
        "summary": "InnoGear Heavy Duty Microphone Stand with Mic Microphone Windscreen and Dual Layered Mic Pop Filter Suspension Boom Scissor Arm Stands for Blue Yeti,Blue Spark and Other Mic",
        "price": { "price": 19.99, "currency": "usd" },
        "shipping": { "price": 43.81, "currency": "usd" },
        "delivery": { "hours-min": 200, "lead": 0 },
        "stock": {},
        "meta-og": {
          "og:url": "https://www.heimkaup.is/friends-safabill?vid=163276",
          "og:title": "Lego Friends safabíll",
          "og:description": "Fáðu þér gómsætan safa á ströndinni! Fyrir 4 ára og eldri",
          "og:image": "https://static.heimkaup.is/images/products/75/102475/3540e-lego-friends-safabill_670x400.jpg",
          "og:type": "product"
        }
      },
      {
        "id": "sadfasdfljlj",
        "domain": "https://www.amazon.com",
        "url": "https://www.amazon.com/dp/B07WJJG65P/ref=tsm_1_fb_lk",
        "title": "LEGO Friends Juice Truck LEGO Truck 41397 Building Kit; Kids Food Truck Featuring LEGO Friends Emma Mini-Doll Figure, New 2020",
        "summary": "Let little chefs will love taking this easy-to-build LEGO Friends Juice Truck for a spin. This set for ages 4+ has a wealth of play starters to keep kids entertained for hours, from whipping up smoothies to chilling on the beach and playing with dolphin toys. This kids' food truck looks great as a desk display or can be driven around a bedroom to wherever the next glass of fruity fun is needed! This LEGO Friends 4+ set is a fun way for preschoolers to learn to build. It includes a special Starter Brick chassis that lets even first-time builders feel the pride of constructing a toy truck all on their own. Includes a simple guide to constructing and playing that helps youngsters understand building steps, and grows their imagination and confidence – it's a great way to introduce kids to LEGO brick construction. Download the LEGO Life app and let kids view this LEGO Friends set in Instruction PLUS. In the various modes they will see their creation really come to life.",
        "price": { "price": 11.99, "currency": "usd" },
        "shipping": { "price": 63.81, "currency": "usd" },
        "delivery": { "hours-min": 200, "lead": 0 },
        "stock": {},
        "meta-og": {
          "og:url": "https://www.heimkaup.is/friends-safabill?vid=163276",
          "og:title": "Lego Friends safabíll",
          "og:description": "LEGO Friends Juice Truck LEGO Truck 41397 Building Kit; Kids Food Truck Featuring LEGO Friends Emma Mini-Doll Figure, New 2020 (103 Pieces)",
          "og:image": "https://images-na.ssl-images-amazon.com/images/I/51eTxvLjOiL._SR600%2c315_PIWhiteStrip%2cBottomLeft%2c0%2c35_PIStarRatingFIVE%2cBottomLeft%2c360%2c-6_SR600%2c315_ZA1%252C759%2c445%2c290%2c400%2c400%2cAmazonEmberBold%2c12%2c4%2c0%2c0%2c5_SCLZZZZZZZ_FMpng_BG255%2c255%2c255.jpg",
          "og:type": "product"
        }
      }
    ]
  },
  "Mic-and-stand": {
    "id": "Mic-and-stand",
    "title": "Mic and stand",
    "image": "https://images-na.ssl-images-amazon.com/images/I/419IMhNJ0NL._SR600%2c315_PIWhiteStrip%2cBottomLeft%2c0%2c35_PIStarRatingFOURANDHALF%2cBottomLeft%2c360%2c-6_SR600%2c315_ZA4%252C576%2c445%2c290%2c400%2c400%2cAmazonEmberBold%2c12%2c4%2c0%2c0%2c5_SCLZZZZZZZ_FMpng_BG255%2c255%2c255.jpg",
    "references": [
      {
        "id": 'nnbndeeee',
        "domain": "https://www.amazon.com",
        "url": "https://www.amazon.com/gp/product/B07CN2C93T?pf_rd_r=CSKDAGFVGEMWWF2PQZ40&pf_rd_p=edaba0ee-c2fe-4124-9f5d-b31d6b1bfbee",
        "title": "Microphone stand",
        "summary": "InnoGear Heavy Duty Microphone Stand with Mic Microphone Windscreen and Dual Layered Mic Pop Filter Suspension Boom Scissor Arm Stands for Blue Yeti,Blue Spark and Other Mic",
        "price": { "price": 19.99, "currency": "usd" },
        "shipping": { "price": 43.81, "currency": "usd" },
        "delivery": { "hours-min": 200, "lead": 0 },
        "stock": {},
        "meta-og": {
          "og:url": "https://www.amazon.com/dp/B07CN2C93T/ref=tsm_1_fb_lk",
          "og:title": "InnoGear Heavy Duty Microphone Stand with Mic Microphone Windscreen and Dual Layered Mic Pop Filter Suspension Boom Scissor Arm Stands for Blue Yeti,Blue Spark and Other Mic",
          "og:description": "InnoGear Heavy Duty Microphone Stand with Mic Microphone Windscreen and Dual Layered Mic Pop Filter Suspension Boom Scissor Arm Stands for Blue Yeti,Blue Spark and Other Mic",
          "og:image": "https://images-na.ssl-images-amazon.com/images/I/419IMhNJ0NL._SR600%2c315_PIWhiteStrip%2cBottomLeft%2c0%2c35_PIStarRatingFOURANDHALF%2cBottomLeft%2c360%2c-6_SR600%2c315_ZA4%252C576%2c445%2c290%2c400%2c400%2cAmazonEmberBold%2c12%2c4%2c0%2c0%2c5_SCLZZZZZZZ_FMpng_BG255%2c255%2c255.jpg",
          "og:type": "product",
          "og:site_name": "Amazon.com",
          "fb:app_id": "465632727431967",
          "og:image:width": "600",
          "og:image:height": "315",
          "description": "Buy InnoGear Heavy Duty Microphone Stand with Mic Microphone Windscreen and Dual Layered Mic Pop Filter Suspension Boom Scissor Arm Stands for Blue Yeti, Blue Spark and Other Mic: Windscreens & Pop Filters - Amazon.com ✓ FREE DELIVERY possible on eligible purchases",
          "title": "Amazon.com: InnoGear Heavy Duty Microphone Stand with Mic Microphone Windscreen and Dual Layered Mic Pop Filter Suspension Boom Scissor Arm Stands for Blue Yeti,Blue Spark and Other Mic: Musical Instruments",
          "keywords": "InnoGear Heavy Duty Microphone Stand with Mic Microphone Windscreen and Dual Layered Mic Pop Filter Suspension Boom Scissor Arm Stands for Blue Yeti,Blue Spark and Other Mic,InnoGear,MU047"
        }
      },
      {
        "id": 'asdfasdfe33',
        "domain": "https://www.amazon.com",
        "url": "amazon.com/dp/B08BY93MRT/ref=sspa_dk_detail_5?psc=1&pd_rd_i=B08BY93MRT&pd_rd_w=Sv0AQ&pf_rd_p=7d37a48b-2b1a-4373-8c1a-bdcc5da66be9&pd_rd_wg=AYRwC&pf_rd_r=5PVG9XX9WSD0E0MGJ3SW&pd_rd_r=e9f06b02-bebb-43f4-8e45-22f2b24d940c&smid=A21LYDGVG6Z2WL&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExN001NVBNREs3VUI1JmVuY3J5cHRlZElkPUEwMDY2NzQxMzVSQVNFNTRDNzFFRiZlbmNyeXB0ZWRBZElkPUEwMTY2MTA5M1VVTTZJS0xWOVNTViZ3aWRnZXROYW1lPXNwX2RldGFpbCZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=",
        "title": "Microphone stand and mic",
        "summary": "USB Microphone for PC - NAHWONG Professional 192KHz/24Bit Condenser Recording Mic Kit for Podcast, Recordings for YouTube, Streaming,Gaming, Recording Music, Voice Over, Livestreaming",
        "price": { "price": 53.99, "currency": "usd" },
        "shipping": { "price": 57.78, "currency": "usd" },
        "delivery": { "hours-min": 240, "lead": 0 },
        "stock": { "count": 10 },
        "meta-og": {
          "og:url": "https://www.amazon.com/dp/B08BY93MRT/ref=tsm_1_fb_lk",
          "og:title": "USB Microphone for PC - NAHWONG Professional 192KHz/24Bit Condenser Recording Mic Kit for Podcast, Recordings for YouTube, Streaming,Gaming, Recording Music, Voice Over, Livestreaming",
          "og:description": "USB Microphone for PC - NAHWONG Professional 192KHz/24Bit Condenser Recording Mic Kit for Podcast, Recordings for YouTube, Streaming,Gaming, Recording Music, Voice Over, Livestreaming",
          "og:image": "https://images-na.ssl-images-amazon.com/images/I/41QsN5D6r6L._SR600%2c315_PIWhiteStrip%2cBottomLeft%2c0%2c35_PIStarRatingFOURANDHALF%2cBottomLeft%2c360%2c-6_SR600%2c315_ZA65%2c445%2c290%2c400%2c400%2cAmazonEmberBold%2c12%2c4%2c0%2c0%2c5_SCLZZZZZZZ_FMpng_BG255%2c255%2c255.jpg",
          "og:type": "product",
          "og:site_name": "Amazon.com",
          "fb:app_id": "465632727431967",
          "og:image:width": "600",
          "og:image:height": "315",
          "description": "Buy USB Microphone for PC - NAHWONG Professional 192KHz/24Bit Condenser Recording Mic Kit for Podcast, Recordings for YouTube, Streaming, Gaming, Recording Music, Voice Over, Livestreaming: Instrument - Amazon.com ✓ FREE DELIVERY possible on eligible purchases",
          "title": "Amazon.com: USB Microphone for PC - NAHWONG Professional 192KHz/24Bit Condenser Recording Mic Kit for Podcast, Recordings for YouTube, Streaming,Gaming, Recording Music, Voice Over, Livestreaming: Home Audio & Theater",
          "keywords": "USB Microphone for PC - NAHWONG Professional 192KHz/24Bit Condenser Recording Mic Kit for Podcast, Recordings for YouTube, Streaming,Gaming, Recording Music, Voice Over, Livestreaming,NAHWONG,N-90"
        }
      }
    ]
  }, "Skjakort-RTX-3080": {
    "id": "Skjakort-RTX-3080",
    "title": "Skjákort RTX 3080",
    "image": "https://d5hu1uk9q8r1p.cloudfront.net/cdn.ht.is/skrar/image/TVP642/ZOT-ZTA30800D10P_1/ZOT-ZTA30800D10P_1_494_350_2.jpg",
    "references": [
      {
        "id": 'xcvxvfghrty4',
        "domain": "https://www.tl.is",
        "url": "https://www.tl.is/product/gaming-geforce-rtx-3080-10gb-trinity",
        "title": "Gaming GeForce RTX 3080 Trinity",
        "summary": "Vertu magnaður með Zotac Gaming GeForce RTX 30 línunni sem er byggð á NVIDIA Ampere tækninni. Inniheldur uppfærða RT Kjarna og Tensor Kjarna, nýjan streymis örgjörva og ofurhratt GDDR6X minni. Zotac Gaming GeForce RTX 3080 Trinity gefur uppfærslu á magnaðri leikjaupplifun með allar graffík stillingar í Ultra.",
        "price": { "price": 159.995, "currency": "isk" },
        "shipping": { "price": 0, "currency": "isk" },
        "delivery": { "hours-min": 48, "lead": 999 },
        "stock": { "count": 0 },
        "meta-og": {
          "og:url": "https://www.tl.is/product/gaming-geforce-rtx-3080-10gb-trinity",
          "og:title": "Zotac Gaming Geforce RTX 3080 10GB Trinity",
          "og:description": "Gaming GeForce RTX 3080 TrinityGDDR6X minniIceStorm 2.0 CoolingWraparound Metal BackplateSPECTRA ljóskerfiFREEZE Fan stopVertu magnaður með Zotac Gaming GeForce RTX 30 línunni sem er byggð áNVIDIA Ampere tækninni. Inniheldur uppfærða RT Kjarna og TensorKjarna, nýjan streymis örgjörva og ...",
          "og:image": "https://d5hu1uk9q8r1p.cloudfront.net/cdn.ht.is/skrar/image/TVP642/ZOT-ZTA30800D10P_1/ZOT-ZTA30800D10P_1_494_350_2.jpg"
        }
      },
      {
        "id": '68789789rtygxdfbc',
        "domain": "https://kisildalur.is",
        "url": "https://kisildalur.is/category/12/products/1806",
        "title": "Palit GeForce RTX 3080 GamingPro 10GB",
        "summary": "Eitt stærsta stökk í sögu Nvidia. Kortið sem þú þarft fyrir 4K leikjaspilun í topp gæðum. Endurbætt Ray-tracing vél gefur nær tvöföld afköst og vönduð kælilausn sér um að halda skrýmslinu vel kældu og eins hljóðlátu og kostur er.",
        "price": { "price": 169.500, "currency": "isk" },
        "shipping": { "price": 0, "currency": "isk" },
        "delivery": { "hours-min": 48, "lead": 999 },
        "stock": { "count": 0 },
        "meta-og": {
          "og:url": "https://kisildalur.is/category/12/products/1806",
          "og:title": "Kisildalur"
        }
      },
      {
        "id": 'asdfasdfnvbnvcx',
        "domain": "https://www.att.is",
        "url": "https://www.att.is/zotac%20gaming%20geforce%20rtx%203080%2010gb%20trinity.html",
        "title": "Zotac Gaming Geforce RTX 3080 10GB Trinity",
        "summary": "Eitt stærsta stökk í sögu Nvidia. Kortið sem þú þarft fyrir 4K leikjaspilun í topp gæðum. Endurbætt Ray-tracing vél gefur nær tvöföld afköst og vönduð kælilausn sér um að halda skrýmslinu vel kældu og eins hljóðlátu og kostur er.",
        "price": { "price": 157.950, "currency": "isk" },
        "shipping": { "price": 0, "currency": "isk" },
        "delivery": { "hours-min": 48, "lead": 999 },
        "stock": { "count": 0 },
        "meta-og": {
          "og:url": "https://www.att.is/zotac%20gaming%20geforce%20rtx%203080%2010gb%20trinity.html",
          "og:type": "product",
          "og:title": "Zotac Gaming Geforce RTX 3080 10GB Trinity",
          "og:description": "Zotac Gaming Geforce RTX 3080 10GB Trinity",
          "og:image": "https://www.att.is/media/catalog/product/cache/859bbaedc2f384718ca013204be684a4/Z/O/ZOT-ZTA30800D10P_1.jpeg"
        }
      }
    ]
  }, "Computer-Screen": {
    "id": "Computer-Screen",
    "title": "Computer Screen",
    "image": "https://d5hu1uk9q8r1p.cloudfront.net/cdn.ht.is/skrar/image/JYN681/PHS-328E1CA.png",
    "references": [
      {
        "id": 'asdfasdfadsf4rere',
        "domain": "https://www.tl.is",
        "url": "https://www.tl.is/product/32-328e1ca-uhd-4k-va-skjar",
        "title": "Philips E-line 32\" UHD VA bogadreginn tölvuskjár",
        "summary": "Upplifðu alvöru innlifun með Philips bognum skjáum. 32\" E línan býður uppá gríðarlegan skírleika með UHD 4K upplausn. Ultra Wide-Lita gæði gefa myndinni líf. Stílhreinn með rammalausri hönnun sem gerir uppsetningu á mörgum eintökum nánast saumlausa. Flicker-Free og LowBlue light tækni minnkar streitu á augun við langan notkunar tíma.",
        "price": { "price": 89.995, "currency": "isk" },
        "shipping": { "price": 10, "currency": "isk" },
        "delivery": { "hours-min": 48, "lead": 999 },
        "stock": {},
        "meta-og": {
          "og:url": "https://www.tl.is/product/32-328e1ca-uhd-4k-va-skjar",
          "og:title": "Philips E-line 32",
          "og:description": "Philips E-line 32",
          "og:image": "https://d5hu1uk9q8r1p.cloudfront.net/cdn.ht.is/skrar/image/JYN681/PHS-328E1CA.png"
        }
      },
      {
        "id": 'gnhjdfgseg',
        "domain": "https://www.tl.is",
        "url": "https://www.tl.is/product/32-328e1ca-uhd-4k-va-skjar",
        "title": "Philips E-line 32\" UHD VA bogadreginn tölvuskjár",
        "summary": "Upplifðu alvöru innlifun með Philips bognum skjáum. 32\" E línan býður uppá gríðarlegan skírleika með UHD 4K upplausn. Ultra Wide-Lita gæði gefa myndinni líf. Stílhreinn með rammalausri hönnun sem gerir uppsetningu á mörgum eintökum nánast saumlausa. Flicker-Free og LowBlue light tækni minnkar streitu á augun við langan notkunar tíma.",
        "price": { "price": 89.995, "currency": "isk" },
        "shipping": { "price": 20, "currency": "isk" },
        "delivery": { "hours-min": 48, "lead": 999 },
        "stock": { "count": Infinity }
      }
    ]
  }, "Sage-Coffee-Machine": {
    "id": "Sage-Coffee-Machine",
    "title": "Sage Coffee Machine",
    "image": "https://elko.is/media/catalog/product/cache/a1ef7bd6921635cb0d9f4dfbee43f1cb/B/E/BES875BSS-72468.jpg",
    "references": [
      {
        "id": '6yghjghj',
        "domain": "https://www.amazon.co.uk",
        "url": "https://www.amazon.co.uk/Sage-BES875UK-Barista-Express-Stainless/dp/B077YZXR1W/ref=sr_1_5?_encoding=UTF8&crid=3BM18CRBO3RUQ&dchild=1&keywords=sage%20coffee%20machine&path=%2FSage-BES875UK-Barista-Express-Stainless%2Fdp%2FB077YZXR1W%2Fref%3Dsr_1_5%3Fcrid%3D3BM18CRBO3RUQ&qid=1604208171&sprefix=sage%2Caps%2C253&sr=8-5&useRedirectOnSuccess=1&",
        "title": "Sage BES875UK The Barista Express with Temp Control Milk Jug, Brushed Stainless Steel",
        "summary": "Inbuilt grinder takes you from whole beans to espresso in under a minute",
        "price": { "price": 89.995, "currency": "isk" },
        "shipping": { "price": 0, "currency": "isk" },
        "delivery": { "hours-min": 48, "lead": 999 },
        "stock": {},
        "meta-og": {
          "og:url": "https://www.amazon.co.uk/dp/B077YZXR1W/ref=tsm_1_fb_lk",
          "og:title": "Sage BES875UK The Barista Express with Temp Control Milk Jug, Brushed Stainless Steel",
          "og:description": "Sage BES875UK The Barista Express with Temp Control Milk Jug, Brushed Stainless Steel",
          "og:image": "https://images-eu.ssl-images-amazon.com/images/I/413OXSAA5QL._SR600%2c315_PIWhiteStrip%2cBottomLeft%2c0%2c35_PIStarRatingFOURANDHALF%2cBottomLeft%2c360%2c-6_SR600%2c315_ZA1%252C324%2c445%2c290%2c400%2c400%2cAmazonEmberBold%2c12%2c4%2c0%2c0%2c5_SCLZZZZZZZ_FMpng_BG255%2c255%2c255.jpg",
          "og:type": "product",
          "og:site_name": "Amazon.co.uk",
          "fb:app_id": "465632727431967",
          "og:image:width": "600",
          "og:image:height": "315",
          "description": "Free delivery and returns on eligible orders. Buy Sage BES875UK The Barista Express with Temp Control Milk Jug, Brushed Stainless Steel at Amazon UK.",
          "title": "Sage BES875UK The Barista Express with Temp Control Milk Jug, Brushed Stainless Steel: Amazon.co.uk: Kitchen & Home",
          "keywords": "Sage BES875UK The Barista Express with Temp Control Milk Jug, Brushed Stainless Steel,BRG Appliances,BES875UK"
        }
      },
      {
        "id": '32324dfgsrgar',
        "domain": "https://www.elko.is",
        "url": "https://elko.is/bes875bss-sage-kaffivel-barista-expresso",
        "title": "Sage Barista Express Espressóvél BES875UK",
        "summary": "Með þessari Sage Barista Espressovél getur hver sem er búið til sitt eigið kafii eins og fynnst á kaffihúsum. Þessi kaffivél er búin mörgum sniðugum eiginleikum og fallegri hönnun sem gerir kaffigerðina skemmtilegari en áður.",
        "price": { "price": 119.995, "currency": "isk" },
        "shipping": { "price": 0, "currency": "isk" },
        "delivery": { "hours-min": 1 * 24, "hours-max": 2 * 24, "lead": 0 },
        "stock": { "count": 100 },
        "meta-og": {
          "og:url": "https://elko.is/bes875bss-sage-kaffivel-barista-expresso",
          "og:type": "product",
          "og:title": "Sage Barista Express Espressóvél BES875UK",
          "og:image": "https://elko.is/media/catalog/product/cache/a1ef7bd6921635cb0d9f4dfbee43f1cb/B/E/BES875BSS-72468.jpg",
          "og:description": "Fyrsta flokks espresso vél frá Sage, helltu uppá frábært kaffi eins og þú værir með lítið kaffihús heima hjá þér.",
          "og:site_name": "Vefverslun ELKO",
          "og:updated_time": "1537361259",
          "product:is_product_shareable": "1"
        }
      },
      {
        "id": '123zdfgsadfg43',
        "domain": "https://www.elko.is",
        "url": "https://elko.is/sage-kaffivel-bambino-plus",
        "title": "Sage Bambino Plus espressóvél",
        "summary": "Sage Bambino Plus SES500BSS er espressóvél með fullkomnum þrýsting og sjálfvirkri mjólkurflóun sem gerir hana einstaklega notendavæna. Hver sem er getur bruggað sér fullkominn kaffibolla og alls konar aðra kaffidrykki auðveldlega með Sage Bambino Plus.",
        "price": { "price": 83.995, "currency": "isk" },
        "shipping": { "price": 0, "currency": "isk" },
        "delivery": { "hours-min": 1 * 24, "hours-max": 2 * 24, "lead": 0 },
        "stock": { "count": 100 },
        "image": "https://elko.is/media/catalog/product/cache/a1ef7bd6921635cb0d9f4dfbee43f1cb/S/E/SES500BSS-99882.jpg",
        "meta-og": {
          "og:url": "https://elko.is/sage-kaffivel-bambino-plus",
          "og:type": "product",
          "og:title": "Sage Bambino Plus espressóvél",
          "og:description": "Sage Bambino Plus SES500BSS er espressóvél með fullkomnum þrýsting og sjálfvirkri mjólkurflóun sem gerir hana einstaklega notendavæna. Hver sem er getur bruggað sér fullkominn kaffibolla og alls konar aðra kaffidrykki auðveldlega með Sage Bambino Plus.",
          "og:image": "https://elko.is/media/catalog/product/cache/a1ef7bd6921635cb0d9f4dfbee43f1cb/S/E/SES500BSS-99882.jpg"
        }
      }
    ]
  }
}

export const gift_lists = {
  "gift_wish_list_akildefault":
  {
    "id": "Akil gifts",
    "title": "Akil gifts",
    "owner": "yGJTgw5O4EhZarFoa9KTH5YJson1",
    "to": "yGJTgw5O4EhZarFoa9KTH5YJson1",
    "changed": 1111111,
    "items": {
      "Sage-Coffee-Machine": {}
    }
  }, "gift_wish_list_akil_byamira": {
    "id": "Akil gifts",
    "title": "Akil gifts",
    "owner": "yGJTgw5O4EhZarFoa9KTH5YJson1",
    "to": "yGJTgw5O4EhZarFoa9KTH5YJson1",
    "changed": 1111111,
    "items": {
      "Mic-and-stand": {},
      "Skjakort-RTX-3080": {}
    }
  }, "gift_wish_list_akilprivate": {
    "id": "Akil-private",
    "title": "Private",
    "owner": "yGJTgw5O4EhZarFoa9KTH5YJson1",
    "to": "yGJTgw5O4EhZarFoa9KTH5YJson1",
    "changed": 1111111,
    "items": {
      "Skjakort-RTX-3080": {},
      "Computer-Screen": {}
    }
  }, "gift_wish_list_amira": {
    "id": "Amira gifts",
    "title": "Amira gifts",
    "owner": "AMIRA-ID",
    "to": "AMIRA-ID",
    "changed": 1111111,
    "items": {
      "Lego-Friends-safabill": {},
      "Lara-fer-i-leikhus": {}
    }
  }, "gift_wish_list_amira_by_akil": {
    "id": "Amira gifts",
    "title": "Amira gifts",
    "owner": "yGJTgw5O4EhZarFoa9KTH5YJson1",
    "to": "AMIRA-ID",
    "changed": 1111111,
    "items": {
      "Lego-Friends-safabill": {}
    }
  }, "gift_wish_list_amira_by_jon": {
    "id": "Amira gifts",
    "title": "Amira gifts",
    "owner": "JON-ID",
    "to": "AMIRA-ID",
    "changed": 1111111,
    "items": {
      "Noi-sirius-Konfekt-Pralin": {},
      "Lara-fer-i-leikhus": {}
    }
  }, "gift_wish_list_jon_by_akil": {
    "id": "Jon gifts",
    "title": "JON gifts",
    "owner": "yGJTgw5O4EhZarFoa9KTH5YJson1",
    "to": "JON-ID",
    "changed": 1111111,
    "items": {
      "Noi-sirius-Konfekt-Pralin": {}
    }
  }
};

export const persons = {
  "AMIRA-ID": {
    "title": "Amíra Mist Kjartansdóttir",
    "avatar": {
      'id': '134249u2',
      'image': 'https://mybluerobot.com/wp-content/plugins/svg-avatars-generator/data/custom-img/girl.png',
      'title': 'Amíra Mist Kjartansdóttir'
    }
  },
  "yGJTgw5O4EhZarFoa9KTH5YJson1": {
    "title": "you",
    "avatar": {
      'id': '334249u2',
      'image': 'https://avatars2.githubusercontent.com/u/36167650?s=400&v=4',
      'title': 'Kjartan Jónsson'
    }
  },
  undefined: {
    "title": "you",
    "avatar": {
      'id': '334249u2',
      'image': 'https://avatars2.githubusercontent.com/u/36167650?s=400&v=4',
      'title': 'Kjartan Jónsson'
    }
  },
  "JON-ID": {
    "title": "jon",
    "avatar": {
      'id': '334249u2',
      'image': 'https://cdn.vectorstock.com/i/1000x1000/71/98/male-avatar-profile-icon-round-man-face-vector-18307198.jpg',
      'title': 'Kjartan Jónsson'
    }
  }
}

export const gift_wishes_profile_akil = {
  "gift_wish_list_akildefault": { "updated": 11111 },
  "gift_wish_list_akil_byamira": { "updated": 11111 },
  "gift_wish_list_akilprivate": { "updated": 11111 },
  "gift_wish_list_amira": { "updated": 11111 },
  "gift_wish_list_amira_by_akil": { "updated": 11111 },
  "gift_wish_list_amira_by_jon": { "updated": 11111 },
  "gift_wish_list_jon_by_akil": { "updated": 11111 }
}


export const giftwish_schema = {
  "title": "Gift wish",
  "description": "Information regarding a gift wish",
  "type": "object",
  "required": ["website"],
  "x-key": "person_id",
  "properties": {
    "list": {
      /*"type":"array",
      "uniqueItems": true,
      "items": {
         "$ref": "#/definitions/GiftList"
      },
      */
      "$ref": "#/definitions/GiftList",
      "title": "Gift list"
    },
    "website": {
      "title": "Home page website",
      "type": "string",
      "format": "uri",
      "edit": { "placeholder": "https://" }
    },
    "title": {
      "title": "Name",
      "type": "string"
    },
    "price": {
      "title": "Price",
      "description": "The price of the gift",
      "type": "number"
    }
  },
  "definitions": {
    "GiftList": {
      "title": "Color",
      "type": "string",
      "oneOf": [
        {
          "type": "string",
          "enum": [
            "#ff0000"
          ],
          "title": "Red"
        },
        {
          "type": "string",
          "enum": [
            "#00ff00"
          ],
          "title": "Green"
        },
        {
          "type": "string",
          "enum": [
            "#0000ff"
          ],
          "title": "Blue"
        }
      ]
    }
  }
}

const avatar_icons = {
  'Motorcycle': 'fa-motorcycle',
  'Bicycle': 'fa-cycle',
  'Car': 'fa-car',
  'Binoculars': 'fa-binoculars',
  'Bus': 'fa-bus',
  'Child': 'fa-child',
  'Camera': 'fa-camera',
  'Cogs': 'fa-cogs',
  'Cab': 'fa-cab',
  'Blind': 'fa-blind',
  'Cubes': 'fa-cubes',
  'Fighter Jet': 'fa-fighter-jet',
  'Book': 'fa-book',
  'Birthday': 'fa-birthday-cake',
  'Gift': 'fa-gift',
  'Paper plane': 'fa-paper-plane',
  'Wrench': 'fa-wrench',
  'User': 'fa-user',
  'Truck': 'fa-truck',
  'Rocket': 'fa-rocket'
}

export const giftlist_schema = {
  "title": "Gift list",
  "description": "List that you can collect your gifts",
  "type": "object",
  "required": ["title"],
  "properties": {
    "title": {
      "title": "Name",
      "type": "string"
    },
    "icon": {
      "title": "Theme",
      'type': "string",
      'enum': Object.values(avatar_icons), //['Motor Cyckle'],
      'enumNames': Object.keys(avatar_icons) //["fa-motorcycle"]      
    },
    "owner": {
      "title": "Owner",
      "type": "string",
      "edit": {
        "widget": "hidden"
      }
    }
  }
}
