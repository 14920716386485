// https://material-ui.com/components/autocomplete/
import React from 'react';
// material ui components
import { withStyles } from '@material-ui/core/styles';
// material ui table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// utils
import { findItemIndexById } from '../../util/ObjUtil';
import EventManager from '../../event/Event';

import { ListBase, events as baseEvents, triggers as baseTriggers } from '../List/ListBase'

export const events = baseEvents;
export const triggers = baseTriggers;
export const view = {
  style: {
    size: {},
    label: {}
  }
}

const style = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  container: {
    maxHeight: 440,
  }
});

class TableComponent extends ListBase {
  /**
   * Used to manage internal state of avatars
   */

  constructor(props) {

    if (!props.view.style) { props.view.style = { label: 'My table', variant: 'outlined' } }
    super(props);

    this.eventDD = this.registerComponent({}, {}, {
      name: "TableComponent",
      author: "Kjartan Jónsson",
      description: "Table component",
      version: "0.3.2"
    });
  }

  getSchemaColumns = (schema) => {
    let columns = [];
    if (!schema) { schema = {}; }
    if (!schema.properties) { schema.properties = {}; }
    for (let prop_name in schema.properties) {
      let prop = schema.properties[prop_name];
      let format = undefined; // by default treat everything as string
      if (prop['type'] === 'number') { format = (value) => value.toLocaleString('en-US'); } // FIXME: local locale
      if (prop['format'] === 'date') { format = (value) => value; } // FIXME: handle date 
      if (prop['type'] === 'date-time') { format = (value) => value; } // FIXME: handle date-time
      columns.push({
        id: prop_name,
        label: prop['title'] || prop_name,
        align: 'left',
        style: { minWidth: 170 },
        format: format
      });
    }
    return columns;
  }

  handleEventOnChange = (evt, newValue, oldValue) => {
    this.handleSelect(newValue, { id: newValue.id }, -1);
  }

  setSelectedId = (id) => {
    const idx = findItemIndexById(id, this.state.data);
    this.setState({ selectedIndex: idx, selectedId: id, data: this.state.data });
    EventManager.getInstance().addEvent(this.props.id, this.eventDD['selected'].id, { id: id }, null);
  }

  getOptionSelected = (opt, val) => {
    // FIXME: key to select
    if (this.state.data && opt) {
      if (this.state.selectedId === opt.id) {
        return this.state.data[this.state.selectedIndex];
      }
    }
    return null;
  }

  handleRowSelect = (key, row, row_index, evt) => {
    EventManager.getInstance().addEvent(this.props.id, this.eventDD['selected'].id, { "row_index": row_index, "row": row, "key": key }, evt);
  }

  renderTableRowCell = (column, row, row_index) => {
    const value = row[column.id];
    return (
      <TableCell key={column.id + '-' + row_index} align={column.align}>
        {column.format ? column.format(value) : value}
      </TableCell>
    );
  }

  render() {
    const { classes } = this.props;

    const columns = this.getSchemaColumns(this.props.schema);

    const key = "id";
    return (
      <TableContainer>
        <Table className={classes.table} aria-label={this.props.view.label} size={this.props.view.style.size} >
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.state.data.map((row, row_index) => {
                //this.state.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, row_index) => {
                return (
                  <TableRow hover tabIndex={-1} key={row[key]} onClick={(evt) => this.handleRowSelect(row[key], row, row_index, evt)} >
                    {columns.map((column) => {
                      return this.renderTableRowCell(column, row, row_index)
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withStyles(style, { withTheme: true })(TableComponent);
