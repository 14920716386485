import React from 'react';

import TextfieldComponent from './TextfieldComponent'
import CheckboxComponent from './CheckboxComponent'

// layout manager
import LayoutManager from '../Layout/Manager'

export function Textfield(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<TextfieldComponent {...props} />);
}

export function registerTextfield() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Textfield,
        type: LayoutManager.TYPE.textfield
    });
}

export function Checkbox(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<CheckboxComponent {...props} />);
}

export function registerCheckbox() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Checkbox,
        type: LayoutManager.TYPE.checkbox
    });
}