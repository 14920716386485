import React from 'react';

import CmmnJS from 'cmmn-js/dist/cmmn-navigated-viewer.production.min.js'; // https://bpmn.io/toolkit/bpmn-js/walkthrough/
// https://github.com/bpmn-io/cmmn-js-examples/tree/master/bundling
import { DDBPMNViewerComponent } from './BPMNViewer'

import {
  commonEventsOMG,
  elementSelectCMMN,
} from './OMGShared'

// event handler
import EventManager from '../../event/Event'

export class DDCMMNViewerComponent extends DDBPMNViewerComponent {

  componentDidMount() {

    const {
      url,
      diagramXML
    } = this.props || { url: null, diagramXML: '' };

    this.getDefinitions = function () {
      return JSON.parse(JSON.stringify(this.cmmnViewer.getDefinitions()));
    }

    const container = this.containerRef.current;
    const self = this;

    this.cmmnViewer = new CmmnJS({ container });

    // register componenet
    this.eventDD = EventManager.getInstance().register(
      this.config.id, {
      enable: {
        alias: [],
        info: {
          name: 'Enable',
          description: 'Allows user to interact with the diagram when selecting the diagram elemements'
        },
        schema: {},
        handler: (obj) => {
          console.info("TODO: enable ", obj);
        }
      },
      disable: (obj) => {
        console.info("TODO: Disable button")
      },
      submit: (obj) => {
        console.info("TODO: send data");
        const event = {
          definition: self.getDefinitions()
        };
        elementSelectCMMN('submitted', this, event, false, this);
      },
      load: (obj) => {
        this.setState({
          diagramXML: obj.xml,
          url: url
        });

        if (obj.xml) {
          this.loadDiagram(obj.xml);
        } else if (obj.url) {
          this.fetchDiagram(obj.url);
        }
      },
      select: (obj) => {
        console.info("TODO: select specified element")
      }
    }, commonEventsOMG, {
      name: 'BPMN Viewer',
      version: '0.1.1',
      author: 'Kjartan Jonsson',
      organization: 'aGameCompany ehf',
      description: 'View Buisness Process Model Notation (BPMN) Diagrams as specified by the OMG group.'
    }
    );

    this.registerCommonEvenHandlers(this.cmmnViewer);

    if (url) {
      return this.fetchDiagram(url);
    }

    if (diagramXML) {
      return this.loadDiagram(diagramXML);
    }

    // TODO: share event - imported
  }

  registerCommonEvenHandlers(cmmnInstance) {

    cmmnInstance.on('element.changed', (event) => {
      // TODO: share event - edited
      const data = {};
      EventManager.getInstance().addEvent(this.config.id, this.eventDD['edited'].id, data, event);
    });

    cmmnInstance.on('element.hover', (event, detailEvent) => { elementSelectCMMN(this.eventDD['hover-over'].id, this, event, false, cmmnInstance); });
    cmmnInstance.on('element.out', (event, detailEvent) => { elementSelectCMMN(this.eventDD['hover-exit'].id, this, event, false, cmmnInstance); });
    cmmnInstance.on('element.click', (event, detailEvent) => { elementSelectCMMN(this.eventDD['selected'].id, this, event, true, cmmnInstance); });
    cmmnInstance.on('element.dblclick', (event, detailEvent) => { elementSelectCMMN(this.eventDD['tap'].id, this, event, true, cmmnInstance); });
    cmmnInstance.on('element.mousedown', (event, detailEvent) => { elementSelectCMMN(this.eventDD['press'].id, this, event, false, cmmnInstance); });
    cmmnInstance.on('element.mouseup', (event, detailEvent) => { elementSelectCMMN(this.eventDD['release'].id, this, event, false, cmmnInstance); });

    cmmnInstance.on('import.done', (event, detailEvent) => {
      const {
        error,
        warnings
      } = event;

      if (error) {
        // TODO: share event - import-error
        return this.handleError(error);
      }

      cmmnInstance.get('canvas').zoom('fit-viewport');

      return this.handleShown(warnings);
    });
  }

  componentWillUnmount() {
    this.cmmnViewer.destroy();
  }

  loadDiagram(diagramXML) {
    const self = this;
    // Share event - loading
    EventManager.getInstance().addEvent(this.config.id, this.eventDD['loading'].id, {
      xml: diagramXML
    }, {});

    // Do the import
    this.cmmnViewer.importXML(diagramXML, function (err) {
      if (!err) {
        this.cmmnViewer.get('canvas').zoom('fit-viewport');
        // Share event - loaded
        EventManager.getInstance().addEvent(self.config.id, self.eventDD['loaded'].id, {
          xml: diagramXML,
          definition: self.cmmnViewer.getDefinitions()
        }, {});
      } else {
        // TODO: better error handling
        // this.handleError(err)
      }
    });
  }

  render() {
    return (
      <div className="react-cmmn-diagram-container" ref={this.containerRef} style={{ flex: 1, height: 1000 }} ></div>
    );
  }
}


