import React from 'react';

import { LayoutBase, events as baseEvents, triggers as baseTriggers } from './LayoutBase';
import Layouter from '../Layout/Layout';
import LayoutManager from '../Layout/Manager'

export const events = baseEvents;
export const triggers = baseTriggers;

export const view = {
  style: {
    spacing: {}
  }
}

export default class LayoutComponent extends LayoutBase {
  /**
   * Used to manage layout
   */

  constructor(props) {
    props.view.style = props.view.style || {};
    super(props);

    this.eventDD = this.registerComponent({}, {}, {
      name: "LayoutComponent",
      author: "Kjartan Jónsson",
      description: "LayoutComponent component",
      version: "0.3.2"
    });
  }

  render() {
    const { classes } = this.props;
    //return LayoutRender(this.props.id, this.props.data, classes, view, 'div');
    const container_id = this.props.id;
    const data = this.props.data;
    const ignore = [];

    let content = []; // rendered content

    for (let item of this.props.data) {
      // check if we should skip generating this item by request of the caller. Example dont allow card action to have another card
      if (ignore.indexOf(item.type) > -1) { console.warn('Using item type=' + item.type + ' not supported in layout for ' + container_id); continue; }
      // create a component identifier
      let id = container_id + (item.id || item.type);
      // build the component
      if (item.type === 'layout') {
        content.push(<Layouter id={id} key={id} classes={classes} data={data} view={item.view} />);
      } else {
        const item_data = data || {};
        const params = { id: id, key: id, classes: classes, data: item.data || item_data[item.pick] || item_data, view: item.view };
        const component = LayoutManager.getInstance().getComponentInstance(item.type, params);
        if (component) {
          content.push(component)
        } else {
          // TODO: notify missing component with type
        }
      }
    }
    return <div>{content}</div>;
  }
}