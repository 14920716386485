import React from 'react';

import BpmnJS from 'bpmn-js/dist/bpmn-modeler.production.min.js'; // https://bpmn.io/toolkit/bpmn-js/walkthrough/
import { DDBPMNViewerComponent } from './BPMNViewer'
// editor requires CSS
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
// common code between BPMNEditor and BPMNViewer
import {
  commonEventsOMG
} from './OMGShared'
// event handler
import EventManager from '../../event/Event'

export class DDBPMNEditorComponent extends DDBPMNViewerComponent {
  // FIXME: is constructor removable?
  constructor(props) {
    super(props);
    this.config = props;
    this.props = {};
    this.state = {};

    this.containerRef = React.createRef();
  }

  componentDidMount() {

    const {
      url,
      diagramXML
    } = this.props || { url: null, diagramXML: '' };

    const container = this.containerRef.current;

    this.bpmnEditor = new BpmnJS({ container });

    // register componenet
    this.eventDD = EventManager.getInstance().register(
      this.config.id, {
      enable: {
        alias: [],
        info: {
          name: 'Enable',
          description: 'Allows user to interact with the diagram when selecting the diagram elemements'
        },
        schema: {},
        handler: (obj) => {
          console.info("TODO: enable ", obj);
        }
      },
      disable: (obj) => {
        console.info("TODO: Disable button")
      },
      submit: (obj) => {
        console.info("TODO: send data")
      },
      load: (obj) => {
        this.setState({
          diagramXML: obj.xml,
          url: url
        });
        if (obj.xml) {
          this.loadDiagram(obj.xml);
        } else if (obj.url) {
          this.fetchDiagram(obj.url);
        }
      },
      select: (obj) => {
        console.info("TODO: select specified element")
      }
    }, commonEventsOMG, {
      name: 'BPMN Editor',
      version: '0.1.1',
      author: 'Kjartan Jonsson',
      organization: 'aGameCompany ehf',
      description: 'View Buisness Process Model Notation (BPMN) Diagrams as specified by the OMG group.'
    }
    );

    this.registerCommonEvenHandlers(this.bpmnEditor);

    if (url) {
      return this.fetchDiagram(url);
    }

    if (diagramXML) {
      return this.loadDiagram(diagramXML);
    }

    // TODO: share event - imported
  }

  componentWillUnmount() {
    this.bpmnEditor.destroy();
  }

  loadDiagram(diagramXML) {
    const self = this;
    // Share event - loading
    EventManager.getInstance().addEvent(this.config.id, this.eventDD['loading'].id, {
      xml: diagramXML
    }, {});

    // Do the import
    this.bpmnEditor.importXML(diagramXML).then(function (result) {
      // Share event - loaded
      EventManager.getInstance().addEvent(self.config.id, self.eventDD['loaded'].id, {
        xml: diagramXML,
        definition: self.bpmnEditor.getDefinitions(),
        warnings: result.warnings
      }, {});
    }).catch(function (err, a, b) {
      // TODO: better error handling
      self.handleError(err);
    });
  }

}
