// https://material-ui.com/components/autocomplete/
import React from 'react';
// material ui components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// styles
import { withStyles } from '@material-ui/core/styles';

import {LayoutBase, events as baseEvents, triggers as baseTriggers} from './LayoutBase'
export const events = baseEvents;
export const triggers = baseTriggers;


const style = (theme) => ({
});

export const view = {
  style:{
    spacing:{},
    direction:{},
    justify:{},
    alignItems:{},
    gridXS:{},
    gridSM:{}
  }
}

class AppbarComponent extends LayoutBase {
  /**
   * Used to manage internal state of avatars
   */

  constructor(props) {
    props.view.style = props.view.style || {};
    props.view.style = {...{spacing:1, direction:'column', justify:'center', alignItems:'stretch'}, ...props.view.style}
    super(props);

    this.eventDD = this.registerComponent({}, {}, {
      name: "AppbarComponent",
      author: "Kjartan Jónsson",
      description: "Appbar component",
      version: "0.3.2"
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            News
          </Typography>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>
    )
  }  
}

export default withStyles(style, { withTheme: true })(AppbarComponent);

export const schema = {
  "$id": "",
  "$schema": "https://json-schema.org/draft/2020-12/schema",
  description: "",
  type: "object",
  properties: {
    xs: {
    },
    sm: {
    },
    item: {
    }
  }
}