import React from 'react';

import CardComponent from './CardComponent'

// layout manager
import LayoutManager from '../Layout/Manager'

export function Card(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<CardComponent {...props} />);
}

export function registerCard() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Card,
        type: LayoutManager.TYPE.card
    });
}