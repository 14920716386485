// https://material-ui.com/components/tabs/
// https://www.digitalocean.com/community/tutorials/react-tabs-component
import React from 'react';
// material ui components

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
// styles
import { withStyles } from '@material-ui/core/styles';

import { LayoutBase, events as baseEvents, triggers as baseTriggers } from './LayoutBase'
export const events = baseEvents;
export const triggers = baseTriggers;


const style = (theme) => ({
});

export const view = {
  style: {
    textColor: {},
    indicatorColor: {},
    tabVariant: {},
    contentMargin: {}
  }
}

class TabsComponent extends LayoutBase {
  /**
   * Used to manage internal state of avatars
   */

  static propTypes = {
    //data: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    // props.view = props.view || {};
    props.view.style = { ...{}, ...props.view.style }
    props.view.style.contentMargin = props.view.style.contentMargin || 3;
    super(props);

    this.eventDD = this.registerComponent({}, {}, {
      name: "TabsComponent",
      author: "Kjartan Jónsson",
      description: "Tabs component",
      version: "0.3.2"
    });

    this.state = { ...this.state, ...{ selectedId: 0 } };

  }

  a11yProps(index) {
    return {
      id: `${this.props.id}-tab-${index}`,
      'aria-controls': `${this.props.id}-tabpanel-${index}`,
    };
  }

  handleChange(evt, idx) {
    this.setSelectedId(this.state.data[idx].id);
  }

  render() {

    const {
      state
    } = this;

    const { classes, view } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs textColor={view.style.textColor} indicatorColor={view.style.indicatorColor} variant={view.style.tabVariant} value={state.selectedIndex} onChange={(evt, idx) => { this.handleChange(evt, idx) }}>
            {state.data.map((itm, idx) =>
            (
              <Tab key={`${this.props.id}-tabpanel-${idx}`} disabled={itm.disabled || false} label={itm.title} {...this.a11yProps(idx)} />
            )
            )}
          </Tabs>
        </AppBar>
        {state.data.map((itm, idx) =>
        (
          <div
            key={`${this.props.id}-tabpanel-${idx}`}
            role="tabpanel"
            hidden={state.selectedIndex !== idx}
            id={`${this.props.id}-tabpanel-${idx}`}
            aria-labelledby={`${this.props.id}-tab-${idx}`}
          >
            {state.selectedIndex === idx && (
              <Box p={view.style.contentMargin}>
                <div>{this.renderContent(classes, itm)}</div>
              </Box>
            )}
          </div>
        )
        )}
      </div>
    )
  }
}

export default withStyles(style, { withTheme: true })(TabsComponent);

export const schema = {
  "$id": "",
  "$schema": "https://json-schema.org/draft/2020-12/schema",
  description: "",
  type: "object",
  properties: {
    disabled: {
      type: "boolean"
    },
    title: {
      type: "string"
    },
    description: {
      type: "string"
    }
  }
}