import { item } from '../components/List/ListBase';
import { md5 } from '../util/ObjUtil'

const friends_overide = {
    "id": "109458624803478",
    "name": "Anita Anna Jónsson",
    "friends": {
        "data": [
            {
                "name": "Ava Algbbhageaafg Qinescu",
                "id": "107547368331321"
            },
            {
                "name": "Ethan Algbedbacjhde Narayananberg",
                "id": "100337532394318"
            }
        ],
        "paging": {
            "cursors": {
                "before": "QVFIUmcxeUFHaU81MUlXTTk4aUtPVGx5LWlsVmtHeWIzTkNrX0pldkg2UXd5bEF0X3E3QUlhblg1dXlFVzNQdl85VjM0ZAEtLWDdxdjAxQUp3ZAVA3RE5nY1dn",
                "after": "QVFIUmpxajNXMTgxamQ3cWU3ODRZAbVF2NVZABMWtCSzRscXN6c0VjRl9oc0ZAYYmFBOGdqYzQ4Q09ZAUUFlQ2o0cEc4VEVmVTdjVTBsVVNDU0JkWGM2d2tsWlpR"
            }
        },
        "summary": {
            "total_count": 4
        }
    }
}

const gift_wish_default_image = "https://img-new.cgtrader.com/items/1912017/e6fa1b26a2/gift-ribbon-red-simple-cartoon-3d-model-obj-mtl-fbx-c4d-blend.jpg"

const updateFriendsCheckboxList = function (data) {
    const friends = [];
    window._user.data.friends.data.forEach((value, key) => {
        let is_shared = false;
        try {
            const friend_id = window._user.friend_ids[value.id];
            const list_id = window._edit_list_object.id.split("-")[0];
            if (window._lists[list_id].share.includes(friend_id)) {
                is_shared = true;
            }
        } catch (e) { console.error("Unexpected error: " + e); }
        friends.push({ id: value.id, title: value.name, selected: is_shared });
    })
    return friends;
}

const updateFriendsList = function (data) {
    // prep avatar
    const user_avatar = {
        id: 'avatar-' + window._user.id,
        view: { 'style': {} },
        data: [{
            id: 'avatar-id-' + window._user.id,
            title: window._user.auth.displayName,
            image: window._user.auth.photoURL
        }]
    }
    // read content from window object
    const items = [];
    for (const [key, value] of Object.entries(window._lists)) {

        let avatar = undefined;
        let user_name = 'Family/Friend';
        let owner = false;
        if (!value.owner) { continue; } // dont show if public lists
        if (value.owner === window._user.id) { avatar = user_avatar; owner = true; }
        else { avatar = JSON.parse(JSON.stringify(user_avatar)); avatar.data[0].image = undefined; avatar.data[0].title = value.name; }
        try { user_name = avatar.data[0].title; } catch (e) { }
        let icon_show = 'font-awesome:fa fa-eye';
        if (!window._user.listing) { window._user.listing = {} }
        if (window._user.listing[key]) {
            if (window._user.listing[key] === -1) {
                icon_show = 'font-awesome:fa fa-eye-slash';
            }
        }
        items.push({
            id: value.id,
            title: value.name,
            subtitle: user_name,
            avatar: avatar,
            secondary: {
                data: [
                    { id: value.id + '-show', icon: icon_show },
                    { id: value.id + '-share', icon: 'font-awesome:fa fa-share', disabled: !owner },
                    { id: value.id + '-edit', icon: 'font-awesome:fa fa-edit', disabled: !owner },
                    { id: value.id + '-delete', icon: 'font-awesome:fa fa-trash', disabled: (!owner || (value.id === window._user.list_id)) }
                ],
                id: 'lists-secondary-actions',
                schema: {},
                type: 'button',
                view: {
                    style: {
                        buttonVariant: 'contained',
                        size: 'small',
                        color: 'secondary'
                    }
                }
            }
        })
    }
    return items;
}

const showGiftList = function (data) {
    let items = []; // collect items
    if (!window._lists) { window._lists = {}; }
    data.forEach((doc) => {
        window._lists[doc.id] = doc;
        // dont show list if it is -1 in listing list
        if (window._user.listing) {
            if (window._user.listing[doc.id] == -1) { return; }
        }
        if (doc.items) {
            for (const [key, value] of Object.entries(doc.items)) {
                let title = value.title;
                if (!title) { continue; }
                if (title.indexOf("http") === 0) { title = "Loading ..."; }
                if (value.og) { value.url = value.url || value.og.url; }

                let affiliated_link = value.url;
                if (value.url.indexOf("amazon") >= 0) { // this is a amazon link, lets add our affiliation
                    // example: https://www.amazon.com/gp/product/B002G9UDY6/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B002G9UDY6&linkCode=as2&tag=giftwishes-20&linkId=5b5057ec631fdd23e634b26e946ff534
                    const affilition = "tag=giftwishes-20";
                    if (value.url.indexOf("?") === -1) { // missing query param, lets add it
                        affiliated_link = value.url + "?" + affilition;
                    } else {
                        affiliated_link = value.url + "&" + affilition;
                    }

                }

                const card = { "id": doc.id + ":" + value.id, "title": title, "media": { "image": value.image || gift_wish_default_image, "data": { url: affiliated_link, target: doc.id } } };

                card.actions = {
                    'id': 'gift_actions',
                    'view': { 'style': {} },
                    'type': 'button',
                    'schema': {},
                    'data': []
                }

                let show_shared = true;

                if (window._user.uid === doc.owner) {
                    card.actions.data = [{
                        'id': 'edit:' + card.id,
                        'icon': 'material-ui:Edit',
                    }, {
                        'id': 'remove:' + card.id,
                        'icon': 'material-ui:Delete',
                    }]
                } else {
                    if (window._user) {
                        card.actions.data.push({
                            'id': card.id + '-add',
                            'icon': 'material-ui:Add'
                        });
                    }
                }

                if (affiliated_link) {
                    card.actions.data.push({
                        'id': 'launch:' + card.id,
                        'icon': 'material-ui:Launch',
                        'link': { url: affiliated_link, target: card.id }
                    });
                }

                items.push(card);
            }
        }
    }
    );
    return items;
}

export const events = [
    // Story: user can log in
    {
        'component': { 'id': 'firebase_login', 'event': 'loggedin' },
        'trigger': { 'id': 'users', 'action': 'upsert' },
        'transform': function (data) {
            window._user = data;
            window._user.uid = data.uid;
            const data_upserted = { id: data.uid, auth: data }
            if (data.providerData) {
                // extract the facebook id to be searchable in Firestore
                data.providerData.forEach(element => {
                    if (element.providerId === 'facebook.com') {
                        // place the facebook id at document root
                        data_upserted.id_facebook = element.uid;
                    }
                });
            }
            return data_upserted;
        }
    },
    {   /// --- lets save info about our user
        'component': { 'id': 'users', 'event': 'upserted' },
        'trigger': { 'id': 'friends', 'action': 'upsert' },
        'transform': function (data) {
            window._user = data;
            window._user.uid = data.auth.uid;
            // window._user.data = friends_overide // TODO: remove this
            return data;
        }
    },
    // Story: when user logs in we get all his lists (event those shared with friends)
    {
        'component': { 'id': 'users', 'event': 'upserted' },
        'trigger': { 'id': 'lists', 'action': 'search' },
        'transform': function (data) {
            return {
                filters: [
                    { attribute: "share", rule: "array-contains", value: window._user.uid }
                ],
                update: true
            };
        }
    },
    // Story: shows logged in user cards and toggle button for lists
    {
        'component': { 'id': 'lists', 'event': 'searched' },
        'trigger': { 'id': 'gifts', 'action': 'push' },
        'transform': showGiftList
    },
    {
        'component': { 'id': 'lists', 'event': 'searched' },
        'trigger': { 'id': 'gift_list_display_toggle', 'action': 'push' },
        'transform': function (data) {
            const toggles = [];
            if (!window._user.listing) { return; }
            for (let [idx, displaying] of Object.entries(window._user.listing)) {
                if (window._lists[idx]) {
                    toggles.push({
                        title: window._lists[idx].name,
                        id: idx + "-show",
                        selected: displaying != -1
                    })
                }
            }
            return toggles;
        }
    },
    // Story: Remove lists content if logging out (hiding)
    {
        'component': { 'id': 'firebase_login', 'event': 'loggedout' },
        'trigger': { 'id': 'gifts', 'action': 'clear' },
        'transform': function (data) {
            return {};
        }
    },
    {
        'component': { 'id': 'firebase_login', 'event': 'loggedout' },
        'trigger': { 'id': 'document_rootgift_layout_id', 'action': 'push' },
        'transform': function (data) {
            return { id: 'gift_layout_add_gift_id', 'hidden': true };
        }
    },
    {
        'component': { 'id': 'firebase_login', 'event': 'loggedout' },
        'trigger': { 'id': 'document_rootgift_layout_id', 'action': 'push' },
        'transform': function (data) {
            return { id: 'gift_layout_welcome_layout_id', 'hidden': false };
        }
    },
    // Story: Add to list: User selects big button and enters details in modal box (allow dropdown list)
    {
        'component': { 'id': 'btn_add_wish', 'event': 'selected' },
        'trigger': { 'id': 'add_wish_object', 'action': 'upsert' },
        'transform': function (data) {
            const ids = data.id.replace("edit:", "").split(":");
            return { "id": "gift_wish_to_add", "list_id": undefined, "item_id": undefined }
        }
    },
    /// -----------------------
    {
        'component': { 'id': 'dlg_list_manager_gift_add_item', 'event': 'cleared' },
        'trigger': { 'id': 'dlg_list_manager_list', 'action': 'push' },
        'transform': function () { return updateFriendsList(); }
    },
    {
        'component': { 'id': 'friends', 'event': 'upserted' },
        'trigger': { 'id': 'document_rootgift_layout_id', 'action': 'push' },
        'transform': function (data) {
            return { id: 'gift_layout_add_gift_id', 'hidden': false };
        }
    },
    {
        'component': { 'id': 'friends', 'event': 'upserted' },
        'trigger': { 'id': 'document_rootgift_layout_id', 'action': 'push' },
        'transform': function (data) {
            return { id: 'gift_layout_welcome_layout_id', 'hidden': true };
        }
    },
    /// -----------------------
    {
        'component': { 'id': 'btn_add_wish', 'event': 'selected' },
        'trigger': { 'id': 'dlg_add_wish', 'action': 'show' },
        'transform': function (data) {
            return data;
        }
    },
    { // --- on change update memory object
        'component': { 'id': 'dlg_add_wish_text', 'event': 'changed' },
        'trigger': { 'id': 'add_wish_object', 'action': 'upsert' },
        'transform': function (data) {
            if (data.value) {
                return { "id": "gift_wish_to_add", "title": data.value };
            }
        }
    },
    { // --- on change update memory object
        'component': { 'id': 'select_list_dropdown', 'event': 'selected' },
        'trigger': { 'id': 'add_wish_object', 'action': 'upsert' },
        'transform': function (data) {
            if (data.id) {
                return { "id": "gift_wish_to_add", "list_id": data.id };
            }
        }
    },
    { // --- close flushes to memory object
        'component': { 'id': 'dlg_add_wish', 'event': 'confirmed' },
        'trigger': { 'id': 'add_wish_object', 'action': 'read' },
        'transform': function (data) {
            window._dlg_add_wish = undefined;
            return "gift_wish_to_add";
        }
    },
    { // --- save to database
        'component': { 'id': 'add_wish_object', 'event': 'read' },
        'trigger': { 'id': 'lists', 'action': 'update' },
        'transform': function (data) {
            const list = window._lists[data.list_id];
            const hash = data.item_id || md5(data.title); // set the item identifier (create or reuse)
            if (!list.items[hash]) { list.items[hash] = {}; }
            if (data.title) {
                if (data.list_src) { // we are copying from another source
                    list.items[hash] = { ...window._lists[data.list_src].items[hash] };
                } // overwrite
                list.items[hash].title = data.title;
                list.items[hash].id = hash;
                if (data.title.indexOf("http") === 0) { list.items[hash].scraping = new Date().getTime(); }
            }
            return list; // FIXME: get this from dropdown
        }
    },
    { // --- check if what we saved is a URL
        'component': { 'id': 'lists', 'event': 'updated' },
        'trigger': { 'id': 'store_findog_api', 'action': 'get' },
        'transform': function (data) {
            let item = null;
            // find item with http that is not scraped
            for (let [idx, itm] of Object.entries(data.items)) {
                if (!itm.title) { continue; }
                if (itm.title.indexOf("http") === 0) {
                    item = itm;
                    break;
                }
            }
            if (item) {
                return {
                    'url': 'http://localhost:4001/gift-wishes-c16c5/us-central1/scrapeURL',
                    'query': {
                        'website': item.title,
                        'item_id': item.id,
                        'list_id': data.id
                    }
                }
            }
        }
    },
    { // --- lets update the item in our list taking account for OG data
        'component': { 'id': 'store_findog_api', 'event': 'got' },
        'trigger': { 'id': 'lists', 'action': 'update' },
        'transform': function (data) {
            const list = window._lists[data.list_id];

            // find our item to update
            for (let [idx, itm] of Object.entries(list.items)) {
                if (itm.id === data.item_id) {
                    itm.scraped = new Date().getTime();
                    itm.image = data.image;
                    delete data.item_id;
                    delete data.list_id;
                    itm.og = data;
                    itm.url = data.url;
                    if (itm.title.indexOf("http") === 0) { itm.title = data.title; }
                    break;
                }
            }
            // save new list
            return list;
        }
    },
    // Story: Remove from list: Card has a delete button (later, hidden in dropdown menu)
    {
        'component': { 'id': 'gift_actions', 'event': 'selected' },
        'trigger': { 'id': 'dlg_delete_gift_makesure', 'action': 'show' },
        'transform': function (data) {
            if (data.id.indexOf("remove") === 0) {
                window._dlg_del_wish = data;
                return data;
            }
        }
    },
    {
        'component': { 'id': 'dlg_delete_gift_makesure', 'event': 'confirmed' },
        'trigger': { 'id': 'gifts', 'action': 'delete' },
        'transform': function (data) {
            if (data.confirm && window._dlg_del_wish) {
                if (window._dlg_del_wish.id.indexOf("remove") === 0) {
                    const id = window._dlg_del_wish.id.replace("remove:", "")
                    return { "id": id };
                }
            }
        }
    },
    {
        'component': { 'id': 'dlg_delete_gift_makesure', 'event': 'confirmed' },
        'trigger': { 'id': 'lists', 'action': 'update' },
        'transform': function (data) {
            if (data.confirm && window._dlg_del_wish) {
                if (window._dlg_del_wish.id.indexOf("remove") === 0) {
                    const ids = window._dlg_del_wish.id.replace("remove:", "").split(":");
                    const list_id = ids[0];
                    const item_id = ids[1];
                    const change = window._lists[list_id];
                    if (change) {
                        if (change.items[item_id]) { delete change.items[item_id]; }
                        window._lists[list_id] = change;
                        return change;
                    }
                }
            }
        }
    },
    // Story: Update item on list: Card has a edit button (later, hidden in dropdown menu)
    {
        'component': { 'id': 'gift_actions', 'event': 'selected' },
        'trigger': { 'id': 'add_wish_object', 'action': 'upsert' },
        'transform': function (data) {
            if (data.id.indexOf("edit") === 0) {
                const ids = data.id.replace("edit:", "").split(":");
                return { "id": "gift_wish_to_add", "list_id": ids[0], "item_id": ids[1] }
            }
        }
    },
    {
        'component': { 'id': 'gift_actions', 'event': 'selected' },
        'trigger': { 'id': 'dlg_add_wish', 'action': 'show' },
        'transform': function (data) {
            if (data.id.indexOf("edit") === 0) {
                const ids = data.id.replace("edit:", "").split(":");
                window._dlg_add_wish = {
                    list_id: ids[0],
                    item_id: ids[1],
                    edit: true
                }
                return {}
            }
        }
    },
    {
        'component': { 'id': 'dlg_add_wish', 'event': 'showing' },
        'trigger': { 'id': 'dlg_add_wish_text', 'action': 'populate' },
        'transform': function (data) {
            let value = "";
            if (window._dlg_add_wish) {
                const list = window._lists[window._dlg_add_wish.list_id];
                if (list) {
                    const itm = list.items[window._dlg_add_wish.item_id] || {};
                    if (itm.title) { value = itm.title; }
                    else {
                        if (itm.og) { value = itm.og.title || ""; }
                    }
                }
            }
            return { "value": value }
        }
    },
    {
        'component': { 'id': 'dlg_add_wish', 'event': 'showing' },
        'trigger': { 'id': 'select_list_dropdown', 'action': 'replace' },
        'transform': function (data) {
            const list = []
            for (const [key, value] of Object.entries(window._lists)) {
                if (value.owner === window._user.uid) {
                    list.push({
                        id: key,
                        title: value.name
                    });
                }
            };
            return list
        }
    },
    {   /// -- select default list
        'component': { 'id': 'select_list_dropdown', 'event': 'replaced' },
        'trigger': { 'id': 'select_list_dropdown', 'action': 'select' },
        'transform': function (data) {
            if (window._dlg_add_wish) {
                const list_id = window._dlg_add_wish.list_id;
                if (list_id) {
                    if (window._lists[list_id].owner === window._user.uid) {
                        return { id: window._dlg_add_wish.list_id }
                    }
                }
            }
            return { id: window._user.list_id }

        }
    },
    // Story: Create list (in + in modal, opens new modal)
    { // --- when user presses submit we submit list name 1
        'component': { 'id': 'dlg_list_manager_btn_list_add', 'event': 'selected' },
        'trigger': { 'id': 'dlg_list_manager_gift_add_item', 'action': 'submit' },
        'transform': function (data) { return data; }
    },
    { // --- users added the list name
        'component': { 'id': 'dlg_list_manager_gift_add_item', 'event': 'submitted' },
        'trigger': { 'id': 'lists', 'action': 'insert' },
        'transform': function (data) {
            const list = {
                name: data.value,
                items: {},
                owner: window._user.uid,
                share: [window._user.uid]
            };
            return list;
        }
    },
    { // --- when user presses submit we clear list name
        'component': { 'id': 'lists', 'event': 'inserted' },
        'trigger': { 'id': 'dlg_list_manager_gift_add_item', 'action': 'clear' },
        'transform': function (data) { return data; }
    },
    // Story: Get list of friends
    // ----- https://graph.facebook.com/v8.0/me?fields=id%2Cname%2Cbirthday%2Cfriends&access_token=EAALywTYjrlQBADKi5uw5WIZAYlfRueQFOyiktAAQA1JnMmffkUtmxtAbAXHo3Xpzg0YwxfDC1qCo13BxLAFerWoVc4piZCn4Q6N9bFRIWnwuMbknHo9nyIb348HtvHQSEIpbOOVYZBk44KFKTVrwGQMAqcDLz6StqKGemZBlA9Y4kLBWwZA7x1dIsU14rP2nvZC1DWZBJvJbAZDZD
    {
        'component': { 'id': 'btn_auth_access', 'event': 'selected' },
        'trigger': { 'id': 'firebase_login', 'action': 'access_scope' },
        'transform': function (data) {
            return {}
        }
    },
    {
        'component': { 'id': 'firebase_login', 'event': 'accessed_scope' },
        'trigger': { 'id': 'users', 'action': 'upsert' },
        'transform': function (data) {
            const scope = {
                id: window._user.uid,
                scope: data
            };
            return scope;
        }
    },
    {
        'component': { 'id': 'firebase_login', 'event': 'scope_failed' },
        'trigger': { 'id': 'console', 'action': 'print' },
        'transform': function (data) {
            console.info("Requesting scope failed, see console for more details")
            return data;
        }
    },
    {   // --- when ever user allows scope we update the friends list
        'component': { 'id': 'firebase_login', 'event': 'accessed_scope' },
        'trigger': { 'id': 'facebook_friends_api', 'action': 'get' },
        'transform': function (data) {
            if (window._user.scope) {
                const access_token = window._user.scope.access_token;
                const request = {
                    url: "https://graph.facebook.com/v8.0/me?fields=id%2Cname%2Cbirthday%2Cfriends&access_token=" + access_token
                }
                return request;

            }
        }
    },
    {   // --- when we get the latest friends we save them to database
        'component': { 'id': 'facebook_friends_api', 'event': 'got' },
        'trigger': { 'id': 'users', 'action': 'upsert' },
        'transform': function (data) {
            const user = {
                "id": window._user.uid,
                "data": data
            }
            return user;
        }
    },
    {   // --- update the UI
        'component': { 'id': 'facebook_friends_api', 'event': 'got' },
        'trigger': { 'id': 'users', 'action': 'upsert' },
        'transform': updateFriendsCheckboxList
    },
    // Story: User can edit gift list through a new window (dialog)
    {
        'component': { 'id': 'btn_edit_list', 'event': 'selected' },
        'trigger': { 'id': 'dlg_gift_list', 'action': 'show' },
        'transform': function (data) {
            return data;
        }
    },
    {
        'component': { 'id': 'dlg_gift_list', 'event': 'showing' },
        'trigger': { 'id': 'dlg_list_manager_list', 'action': 'push' },
        'transform': updateFriendsList
    },
    // Story: Remove list
    {
        'component': { 'id': 'edit_list_object', 'event': 'inserted' },
        'trigger': { 'id': 'dlg_delete_makesure', 'action': 'show' },
        'transform': function (data) {
            const ids = data.data.id.split("-")
            if (ids[1] === 'delete') {
                return data;
            }
        }
    },
    {
        'component': { 'id': 'dlg_delete_makesure', 'event': 'confirmed' },
        'trigger': { 'id': 'lists', 'action': 'delete' },
        'transform': function (data) {
            const editing = window._edit_list_object;
            const ids = editing.id.split("-")
            if (ids[1] === 'delete') {
                // make sure we own it
                if (window._lists[ids[0]].owner === window._user.id) {
                    delete window._lists[ids[0]];
                    return ids[0];
                }
            }
        }
    },
    /// -- save action data
    {
        'component': { 'id': 'lists-secondary-actions', 'event': 'selected' },
        'trigger': { 'id': 'edit_list_object', 'action': 'insert' },
        'transform': function (data) {
            window._edit_list_object = data;
            return { id: 'edit', data: data };
        }
    },
    {
        'component': { 'id': 'edit_list_object', 'event': 'inserted' },
        'trigger': { 'id': 'dlg_show_friends', 'action': 'show' },
        'transform': function (data) {
            if (data['data']['id'].indexOf('-share') > 0) {
                return data['data'];
            }
        }
    },
    {
        'component': { 'id': 'lists', 'event': 'deleted' },
        'trigger': { 'id': 'dlg_list_manager_list', 'action': 'delete' },
        'transform': function (data) {
            return data;
        }
    },
    // Story: Show friends list
    {
        'component': { 'id': 'btn_show_friends', 'event': 'selected' },
        'trigger': { 'id': 'dlg_show_friends', 'action': 'show' },
        'transform': function (data) {
            return {}
        }
    },
    {
        'component': { 'id': 'dlg_show_friends', 'event': 'showing' },
        'trigger': { 'id': 'dlg_show_friends_list', 'action': 'push' },
        'transform': updateFriendsCheckboxList
    },
    // Story: Un-share list with friend: by pressing a button on list of lists. Then un-selecting the friend to share with.
    // +Story: Share list with friend: by pressing a button on list of lists. Then selecting the friend to share with.
    {   /// -- fetch id
        'component': { 'id': 'dlg_show_friends_list', 'event': 'selected' },
        'trigger': { 'id': 'store_findid_api', 'action': 'get' },
        'transform': function (data) {
            const friends = window._user.friend_ids || {};
            // only ask database if we are enabling and we dont have this data
            if (data.selected && (friends[data.id] === undefined)) {
                return {
                    'url': 'http://localhost:4001/gift-wishes-c16c5/us-central1/friendId',
                    'query': {
                        'id_facebook': data.id
                    }
                }
            }
        }
    },
    {   /// -- store id
        'component': { 'id': 'store_findid_api', 'event': 'got' },
        'trigger': { 'id': 'users', 'action': 'upsert' },
        'transform': function (data) {
            // save info to our user list
            const friend_ids = window._user.friend_ids || {};
            if (data.id_facebook) { friend_ids[data.id_facebook] = data.uid; }
            return {
                id: window._user.id,
                friend_ids: friend_ids
            };
        }
    },
    {   /// -- if we already have the user id, lets use it to store/remove in list share array
        'component': { 'id': 'dlg_show_friends_list', 'event': 'selected' },
        'trigger': { 'id': 'lists', 'action': 'update' },
        'transform': function (data) {
            const friends = window._user.friend_ids || {};
            const friend_id = friends[data.id]
            if (!friend_id) { return; } // must have friend id to add to list
            if (window._edit_list_object && window._edit_list_object.id) {
                const list_id = window._edit_list_object.id.split("-")[0];
                const list = window._lists[list_id];
                if (!list) { return; } // must have list
                const want_to_share = list.share.includes(friend_id);
                if (!data.selected && want_to_share) {
                    list.share = list.share.filter(e => e !== friend_id);
                }
                if (data.selected && (!want_to_share)) {
                    list.share.push(friend_id);
                }
                return list;
            }
        }
    },
    // Story: Rename list
    {
        'component': { 'id': 'edit_list_object', 'event': 'inserted' },
        'trigger': { 'id': 'dlg_edit_list', 'action': 'show' },
        'transform': function (data) {
            if (data['data']['id'].indexOf('-edit') > 0) {
                return data['data'];
            }
        }
    },
    {
        'component': { 'id': 'dlg_edit_list', 'event': 'showing' },
        'trigger': { 'id': 'dlg_edit_list_text', 'action': 'populate' },
        'transform': function (data) {
            const a = window._edit_list_object.id.split("-")[0];
            if (window._lists[a]) {
                return { value: window._lists[a].name }
            }
        }
    },
    {
        'component': { 'id': 'dlg_edit_list', 'event': 'confirmed' },
        'trigger': { 'id': 'dlg_edit_list_text', 'action': 'submit' },
        'transform': function (data) {
            return data;
        }
    },
    {
        'component': { 'id': 'dlg_edit_list_text', 'event': 'submitted' },
        'trigger': { 'id': 'lists', 'action': 'upsert' },
        'transform': function (data) {
            const list_id = window._edit_list_object['id'].split('-')[0];
            const name = data.value;
            // find the id in lists
            const updated_list = window._lists[list_id];
            return { id: list_id, name: name };
        }
    },
    {
        'component': { 'id': 'lists', 'event': 'upserted' },
        'trigger': { 'id': 'dlg_list_manager_list', 'action': 'push' },
        'transform': function (data) {
            return updateFriendsList()
        }
    },
    // Story: Signup new user: Create default list and show simple tutorial
    {
        'component': { 'id': 'lists', 'event': 'searched' },
        'trigger': { 'id': 'lists', 'action': 'upsert' },
        'transform': function (data) {
            const default_list = { share: [], items: {}, name: 'Gift Wishes', owner: window._user.uid }
            // if user does not have a default list
            if (data.length === 0) {
                if (!window._user.list_id) {
                    //  --- lets create it using a hash function as id
                    default_list['id'] = md5(window._user.uid)
                    return default_list;
                }
            }
        }
    },
    {
        'component': { 'id': 'lists', 'event': 'upserted' },
        'trigger': { 'id': 'users', 'action': 'upsert' },
        'transform': function (data) {
            // lets this id be saved to our user account
            if (data.id === md5(window._user.uid)) {
                return {
                    id: window._user.uid,
                    list_id: md5(window._user.uid)
                };
            }
        }
    },
    {
        'component': { 'id': 'users', 'event': 'upserted' },
        'trigger': { 'id': 'console', 'action': 'print' },
        'transform': function (data) {
            if (!window._user.list_id) {
                console.info("==== NEW USER: todo - run tutor ====")
            }
            return data;
        }
    },
    // Story: Select the list to view
    {
        'component': { 'id': 'edit_list_object', 'event': 'inserted' },
        'trigger': { 'id': 'users', 'action': 'upsert' },
        'transform': function (data) {
            const ids = data.data.id.split("-")
            const list_id = ids[0];
            const action = ids[1];
            if (action === 'show') { // listing
                if (!window._user.listing) { window._user.listing = {} }
                if (window._user.listing[list_id] && (window._user.listing[list_id] == -1)) {
                    window._user.listing[list_id] = 1;
                } else {
                    window._user.listing[list_id] = -1;
                }
                return window._user;
            }
        }
    },
    {
        'component': { 'id': 'edit_list_object', 'event': 'inserted' },
        'trigger': { 'id': 'gifts', 'action': 'delete' },
        'transform': function (data) {
            const deleted = [];
            const ids = data.data.id.split("-")
            const list_id = ids[0];
            const action = ids[1];
            if (action === 'show') { // listing
                if (!window._user.listing) { window._user.listing = {} }
                if (window._user.listing[list_id] && (window._user.listing[list_id] == -1)) {
                    // run through all the gifts and create the id to remove them
                    for (let [itm_idx, itm] of Object.entries(window._lists[list_id].items)) {
                        // for each item in list
                        deleted.push({ id: list_id + ":" + itm_idx });
                    }
                }
            }
            return deleted;
        }
    },
    {
        'component': { 'id': 'gifts', 'event': 'deleted' },
        'trigger': { 'id': 'dlg_gift_list', 'action': 'close' },
        'transform': function (data) {
            return data;
        }
    },
    // Story: show / hide list on top
    {
        'component': { 'id': 'gift_list_display_toggle', 'event': 'selected' },
        'trigger': { 'id': 'console', 'action': 'print' },
        'transform': function (data) {
            return data;
        }
    },
    {
        'component': { 'id': 'gift_list_display_toggle', 'event': 'selected' },
        'trigger': { 'id': 'edit_list_object', 'action': 'insert' },
        'transform': function (data) {
            window._edit_list_object = data;
            return { id: 'edit', data: data };
        }
    },
    // Story: show recommended gift wish lists
    {
        'component': { 'id': 'gift_actions', 'event': 'selected' },
        'trigger': { 'id': 'console', 'action': 'print' },
        'transform': function (data) {
            if (data.id.indexOf("-add") >= 0) {
                return data;
            }
        }
    },
    {
        // -- set the add_wish_object
        'component': { 'id': 'gift_actions', 'event': 'selected' },
        'trigger': { 'id': 'add_wish_object', 'action': 'upsert' },
        'transform': function (data) {
            if (data.id.indexOf("-add") > 0) {
                const id = data.id.replace("-add", "");

                const ids = id.split(":");
                window._dlg_add_wish = {
                    id: "gift_wish_to_add",
                    list_id: ids[0],
                    list_src: ids[0],
                    item_id: ids[1]
                }

                return window._dlg_add_wish;
            }
        }
    },
    {
        // -- show the dialog box
        'component': { 'id': 'gift_actions', 'event': 'selected' },
        'trigger': { 'id': 'dlg_add_wish', 'action': 'show' },
        'transform': function (data) {
            if (data.id.indexOf("-add") > 0) {
                const id = data.id.replace("-add", "");
                return data
            }
        }
    },
    // Background processing generating affilicated links
    // Story: Show public gift recommendations
    // Story: when user logs in we get all his lists (event those shared with friends)
    {
        'component': { 'id': 'app', 'event': 'ready' },
        'trigger': { 'id': 'shared', 'action': 'search' },
        'transform': function (data) {
            window._user = {};
            return {
                filters: [
                ],
                update: true
            };
        }
    },
    {
        'component': { 'id': 'shared', 'event': 'searched' },
        'trigger': { 'id': 'gifts', 'action': 'push' },
        'transform': showGiftList
    },
    // Story: Share gift list
    {
        'component': { 'id': 'shared', 'event': 'searched' },
        'trigger': { 'id': 'page_parameter', 'action': 'read' },
        'transform': function (data) {
            return data;
        }
    }, // check if this has a uri of ?list=32lj24jl24jl, then continue to add to our list
    {
        'component': { 'id': 'page_parameter', 'event': 'read' },
        'trigger': { 'id': 'console', 'action': 'print' },
        'transform': function (data) {
            return data;
        }
    },
    {
        'component': { 'id': 'page_parameter', 'event': 'read' },
        'trigger': { 'id': 'users', 'action': 'upsert' },
        'transform': function (data) {
            var list_id = data.list;
            // check that we got a parameter with list id and user data
            if (list_id && (Object.keys(window._user).length > 0)) {
                if (!window._user.listing) { window._user.listing = {} }
                if (!window._user.listing[list_id]) {
                    window._user.listing[list_id] = 1;
                }
                window._user.id = window._user.uid;
                return window._user;
            }
        }
    },
    // Story: Select image open store
    {
        'component': { 'id': 'gifts', 'event': 'selected' },
        'trigger': { 'id': 'page_view', 'action': 'open' },
        'transform': function (data) {
            return data;
        }
    }


]
