import React from 'react';
// import DDLayout from '../src/components/Layout/Layout';
import { Layout } from './components/Grid/Grid';

import EventHandler from './event/Event';
import Collection from './components/Firebase/Collection';
import ObjectCollection from './components/Data/ObjectCollection';
import ViewParameter from './components/Data/ViewParameter';
import ViewPage from './components/Data/ViewPage';

import registerComponents from './components/Components';
import { Dialog } from './components/Modal/Modal';
import EventManager from './event/Event';

import { layout, dialogs } from './examples/gift-wishes-layout';
import { events } from './examples/gift-wishes-events';

import { AppAuth } from './components/Firebase/AppAuth'
import { WebJSONRequest } from './components/Data/WebJSONRequest'

// ----

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './GiftWishTheme'

// ----


// firebase -------- start
import { config_firebase } from './config'
import firebase from 'firebase/app';

// Instantiate a Firebase app.
export const firebaseApp = firebase.initializeApp(config_firebase);
// firebase.analytics();
// firebase -------- stop

// register components
registerComponents();

// register custom debug-console for stories console
EventHandler.getInstance().clearAll();
EventHandler.getInstance().register('console', {
  print: (obj) => {
    console.info("------------- DEBUG-STORY ----------------");
    console.info(obj);
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <div>
          <div style={{ direction: 'rtl' }}>
            <AppAuth id={'firebase_login'} firebaseApp={firebaseApp} />
          </div>
          <Layout
            id="document_root"
            schema={{}}
            view={{
            }}
            data={layout.root}
          />
          {dialogs.map((itm, idx) => (<Dialog key={itm.id} id={itm.id} data={itm.data} view={itm.view} schema={itm.schema} />))}
        </div>
      </div >
    </ThemeProvider>
  );
}

export default App;

// connect to database
const giftlistCollection = new Collection("lists", firebaseApp, {});
const giftsharedCollection = new Collection("shared", firebaseApp, {});
// connect to database
const usersCollection = new Collection("users", firebaseApp, {});
// memory database
const wishMemory = new ObjectCollection("add_wish_object", {}, {});
const listMemory = new ObjectCollection("add_list_object", {}, {});
const removeListMemory = new ObjectCollection("edit_list_object", {}, {});
const friendsMemory = new ObjectCollection("friends", {}, {});
const friendsAPI = new WebJSONRequest("facebook_friends_api", {});
const storeFindIdAPI = new WebJSONRequest("store_findid_api", {});
const storeFindOGAPI = new WebJSONRequest("store_findog_api", {});
const viewParameter = new ViewParameter("page_parameter", {});
const viewPage = new ViewPage("page_view", {});
// memory database
// ---- TODO const listhMemory = new ObjectCollection("list_objects", {}, {});


// set dealy since we need to register all the component first
// this is for being able to apply filtering on registered components
EventHandler.getInstance().watch(events);
// notify ready event
EventManager.getInstance().addEvent('app', 'ready', {}, {});
