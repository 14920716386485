import React from 'react';

import ContainerComponent from './ContainerComponent'
import TabsComponent from './TabsComponent'
import GridComponent from './GridComponent'
import AccordionComponent from './AccordionComponent'
import AppbarComponent from './AppbarComponent'
import LayoutComponent from './LayoutComponent'
import PageComponent from './PageComponent'


// layout manager
import LayoutManager from '../Layout/Manager'

export function Layout(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<LayoutComponent {...props} />);
}

export function Container(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<ContainerComponent {...props} />);
}

export function Grid(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<GridComponent {...props} />);
}

export function Accordion(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<AccordionComponent {...props} />);
}

export function Tabs(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<TabsComponent {...props} />);
}

export function Appbar(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<AppbarComponent {...props} />);
}


export function Page(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<PageComponent {...props} />);
}


export function registerContainer() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Container,
        type: LayoutManager.TYPE.container
    });
}

export function registerGrid() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Grid,
        type: LayoutManager.TYPE.grid
    });
}

export function registerAccordion() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Accordion,
        type: LayoutManager.TYPE.accordion
    });
}

export function registerTabs() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Tabs,
        type: LayoutManager.TYPE.tabs
    });
}

export function registerAppbar() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Appbar,
        type: LayoutManager.TYPE.appbar
    });
}

export function registerLayout() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Layout,
        type: LayoutManager.TYPE.layout
    });
}

export function registerPage() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Page,
        type: LayoutManager.TYPE.page
    });
}