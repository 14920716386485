// event handler
import EventManager from '../../event/Event';

export const COMPONENT_STANDARD_FAILURE = {
  "$id": "https://example.com/card.schema.json",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "description": "Standard error message",
  "type": "object",
  "properties": {
    "url": {
      "type": "string",
      "format": "uri"
    },
    "xml": {
      "type": "string",
      "format": "xml"
    }
  }
}

export const COMPONENT_SCHEMA_BPMN_BUISSINESSOBJECT = {
  bpmnType: 'string',
  bpmnId: 'string',
  name: 'string',
}

export const COMPONENT_SCHEMA_BPMN_DEFINITION = {
  type: 'object'
}

export const COMPONENT_SCHEMA_CMMN_DEFINITION = {
  type: 'object'
}

export const COMPONENT_SCHEMA_CMMN_FULL = {
  '$id': 'https://example.com/card.schema.json',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  title: 'Diagram full',
  description: 'User saves CMMN',
  type: 'object',
  properties: {
    xml: { type: 'string', format: 'xml' },
    definition: COMPONENT_SCHEMA_CMMN_DEFINITION
  }
}

export const COMPONENT_SCHEMA_BPMN_FULL = {
  '$id': 'https://example.com/card.schema.json',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  title: 'Diagram full',
  description: 'User saves BPMN',
  type: 'object',
  properties: {
    xml: { type: 'string', format: 'xml' },
    definition: COMPONENT_SCHEMA_BPMN_DEFINITION
  }
}


export const COMPONENT_SCHEMA_BPMN_SELECT_DETAIL = {
  '$id': 'https://example.com/card.schema.json',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  title: 'Diagram selection',
  description: 'User selects element in a diagram',
  type: 'object',
  properties: {
    element: COMPONENT_SCHEMA_BPMN_BUISSINESSOBJECT,
    parent: COMPONENT_SCHEMA_BPMN_BUISSINESSOBJECT,
    incoming: {
      type: 'array',
      items: {
        type: 'object',
        properties: COMPONENT_SCHEMA_BPMN_BUISSINESSOBJECT
      }
    },
    outgoing: {
      type: 'array',
      items: {
        type: 'object',
        properties: COMPONENT_SCHEMA_BPMN_BUISSINESSOBJECT
      }
    },
    definition: COMPONENT_SCHEMA_BPMN_DEFINITION
  }
}

export const COMPONENT_SCHEMA_BPMN_SELECT_BASIC = {
  '$id': 'https://example.com/card.schema.json',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  title: 'Diagram selection',
  description: 'User selects element in a diagram',
  type: 'object',
  properties: {
    element: COMPONENT_SCHEMA_BPMN_BUISSINESSOBJECT
  }
}

export const COMPONENT_SCHEMA_BPMN_LOADING = {
  '$id': 'https://example.com/card.schema.json',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  title: 'Diagram loading',
  description: 'XML diagram is loading',
  type: 'object',
  properties: {
    xml: {
      type: 'string',
      format: 'xml'
    }
  }
}

export const COMPONENT_SCHEMA_BPMN_LOADED = {
  '$id': 'https://example.com/card.schema.json',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  title: 'Diagram loading',
  description: 'XML diagram is loading',
  type: 'object',
  properties: {
    xml: {
      type: 'string',
      format: 'xml'
    },
    definition: COMPONENT_SCHEMA_BPMN_DEFINITION
  }
}

export function elementSelectBPMN(dd_event, self, event, detailed, inst) {
  // TODO: share event - select
  const bpmnView = inst;
  const elementIncoming = [];
  const elementOutgoing = [];
  const buisinessEvent = event.element.businessObject;

  if (!buisinessEvent) {
    // TODO: react to error
    return;
  }

  let event_info = {
    element: {
      bpmnId: buisinessEvent.id,
      bpmnType: buisinessEvent['$type'],
      label: buisinessEvent.name
    }
  }

  if (detailed) {
    if (buisinessEvent.incoming) {
      buisinessEvent.incoming.forEach((item, idx) => {
        elementIncoming.push({
          bpmnId: item.id,
          bpmnType: item['$type']
        });
      });
    }

    if (buisinessEvent.outgoing) {
      buisinessEvent.outgoing.forEach((item, idx) => {
        elementOutgoing.push({
          bpmnId: item.id,
          bpmnType: item['$type']
        });
      });
    }

    event_info.incoming = elementIncoming;
    event_info.outgoing = elementOutgoing;
    event_info.definition = bpmnView.getDefinitions();
    event_info.parent = null;

    if (event.element.parent) {
      event_info.parent = {
        bpmnId: event.element.parent.id,
        bpmnType: event.element.parent['type'] || event.element.parent['$type']
      }
    }
  }

  EventManager.getInstance().addEvent(self.config.id, dd_event, event_info, self);
}


export function elementSelectCMMN(dd_event, self, event, detailed, inst) {
  // TODO: share event - select
  const bpmnView = inst;
  const elementIncoming = [];
  const elementOutgoing = [];
  const eventElement = event.element;
  const buisinessEvent = event.element.businessObject;

  if (!buisinessEvent) {
    // TODO: react to error
    return;
  }

  let event_info = {
    element: {
      id: buisinessEvent.id,
      type: buisinessEvent['$type']
    }
  }
  if (buisinessEvent.definitionRef) {
    event_info.element.label = buisinessEvent.definitionRef.name;
    event_info.element.category = buisinessEvent.definitionRef.$type;
    event_info.element.blocking = buisinessEvent.definitionRef.isBlocking;
  }

  if (detailed) {
    if (buisinessEvent.entryCriteria) {
      buisinessEvent.entryCriteria.forEach((item, idx) => {
        elementIncoming.push({
          id: item.id,
          type: item['$type']
        });
      });
    }

    if (eventElement.outgoing) {
      eventElement.outgoing.forEach((item, idx) => {
        elementOutgoing.push({
          id: item.id,
          type: item['type']
        });
      });
    }

    event_info.incoming = elementIncoming;
    event_info.outgoing = elementOutgoing;
    event_info.definition = bpmnView.getDefinitions();
    event_info.parent = null;

    if (event.element.parent) {
      event_info.parent = {
        id: event.element.parent.id,
        type: event.element.parent['type'] || event.element.parent['$type']
      }
    }
  }

  EventManager.getInstance().addEvent(self.config.id, dd_event, event_info, self);
}

/*
export const eventHandlerSubmitCMMN = (obj) => {
  console.info("TODO: send data");
  const event = {
    definition: this.cmmnViewer.getDefinitions()
  };
  elementSelectCMMN('submitted', this, event, false, this);
}
*/
export const commonEventsOMG = {
  failure: {
    alias: [],
    info: {
      name: 'Failure',
      description: 'Returns issues this component detects when executing actions'
    },
    schema: COMPONENT_STANDARD_FAILURE,
    handler: (obj) => {
      debugger;
    }
  },
  loading: {
    alias: [],
    info: {
      name: 'Loading',
      description: 'Loaded BPMN XML string'
    },
    schema: COMPONENT_SCHEMA_BPMN_LOADING
  },
  loaded: {
    alias: ['open', 'fetch', 'streamed'],
    info: {
      name: 'Loaded',
      description: 'XML diagram has been loaded'
    },
    schema: COMPONENT_SCHEMA_BPMN_LOADED
  },
  selected: {
    alias: ['click', 'choose'],
    info: {
      name: 'Select',
      description: 'Diagram element selected'
    },
    schema: COMPONENT_SCHEMA_BPMN_SELECT_DETAIL
  },
  tap: {
    alias: ['dblclick'],
    info: {
      name: 'Tap',
      description: 'Diagram element tapped'
    },
    schema: COMPONENT_SCHEMA_BPMN_SELECT_DETAIL
  },
  press: {
    alias: ['mouse-pressed'],
    info: {
      name: 'Press',
      description: 'Diagram element pressed'
    },
    schema: COMPONENT_SCHEMA_BPMN_SELECT_BASIC
  },
  release: {
    alias: ['mouse-release'],
    info: {
      name: 'Release',
      description: 'Diagram element released after being pressed'
    },
    schema: COMPONENT_SCHEMA_BPMN_SELECT_BASIC
  },
  'hover-over': {
    alias: ['hover'],
    info: {
      name: 'Hover Over',
      description: 'Mouse hovering over element'
    },
    schema: COMPONENT_SCHEMA_BPMN_SELECT_BASIC
  },
  'hover-exit': {
    alias: ['out'],
    info: {
      name: 'Hover Exit',
      description: 'Mouse moved from hovering over element'
    },
    schema: COMPONENT_SCHEMA_BPMN_SELECT_BASIC
  },
  submitted: {
    alias: ['save', 'export'],
    info: {
      name: 'Submitted',
      description: 'User submitting diagram'
    },
    schema: COMPONENT_SCHEMA_BPMN_FULL
  },
  edited: {}
  /* 
  ,editing: {},
  'enabling': {},
  'enabled': {},
  'disabling': {},
  'disabled': {}
  */
}