// https://material-ui.com/components/tabs/
// https://www.digitalocean.com/community/tutorials/react-tabs-component
import React from 'react';
// styles
import { withStyles } from '@material-ui/core/styles';

import { LayoutBase, events as baseEvents, triggers as baseTriggers } from './LayoutBase'
export const events = baseEvents;
export const triggers = baseTriggers;

const style = (theme) => ({
});

export const view = {
  style: {
  }
}

class ContainerComponent extends LayoutBase {
  /**
   * Displays one rendered content at a time.
   */

  constructor(props) {
    props.view.style = { ...{}, ...props.view.style }
    super(props);
    this.state = { data: (props.data || []), selectedIndex: 0, selectedId: undefined }

    this.eventDD = this.registerComponent({}, {}, {
      name: "ContainerComponent",
      author: "Kjartan Jónsson",
      description: "Container component displays one rendered component at a time",
      version: "0.3.2"
    });
  }

  render() {

    const { classes } = this.props;

    // FIXME: render all views (do we need this)?
    this.state.data.forEach((itm, idx, arr) => {
      arr[idx].__render = this.renderContent(classes, itm);
      arr[idx].__visible = false;
    });

    if ((0 <= this.state.selectedIndex) && (this.state.selectedIndex < this.state.data.length)) {
      this.state.data[this.state.selectedIndex].__visible = true;
    } else {
      // TODO: notify index out of bounds
    }
    return (
      <div className={classes.root}>
        {this.state.data.map((itm, idx) =>
        (
          <div key={itm.id} style={{ display: (itm.__visible ? 'block' : 'none') }}>{itm.__render}</div>
        )
        )}
      </div>
    )
    /*
    return (
      <div className={classes.root}>
        {itm.__render}
      </div>
    )
    */
  }
}

export default withStyles(style, { withTheme: true })(ContainerComponent);
