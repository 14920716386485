
import 'firebase/firestore';

// event handler
import EventManager from '../../event/Event';

/***
 * Actions and events
 * create: inserting, inserted, failed:exists
 * read: failed:missing, 
 */

export default function Collection(collection_name, firebaseApp, schema) {

    const raiseSuccessEvent = (event_name, data, evt) => {
        EventManager.getInstance().addEvent(collection_name, event_name, data, evt);
    };

    const raiseFailureEvent = (event_name, data, evt) => {
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises
        EventManager.getInstance().addEvent(collection_name, event_name, data, evt);
    };

    const isValidDocumentSchema = (doc, schema) => {
        // returns true if doc validates against schema
        return true;
    }

    const isValidDocumentId = (document_id) => {
        return document_id !== null && document_id !== undefined;
    }

    const getValidDocumentId = (obj) => {
        if (typeof (obj) === "string") { return obj; }
        const obj_id = obj.id || obj.identifier;
        if (isValidDocumentId(obj_id)) { return obj_id; }
        return null;
    }

    const setValidDocumentId = (doc_id, obj) => {
        obj.id = doc_id;
        return obj;
    }

    const db = firebaseApp.firestore();
    if (window.location.hostname.includes("localhost")) { // FIXME: do this behind the scenes
        // db.useEmulator('localhost', 8080);
    }
    const db_collection = db.collection(collection_name);

    // register componenet
    EventManager.getInstance().register(
        collection_name, {
        insert: {
            schema: {},
            handler: (obj) => {
                // TODO: make sure we dont send 'undefined' data
                // https://firebase.google.com/docs/firestore/manage-data/add-data
                const schema_validation = isValidDocumentSchema(obj, schema);
                if (schema_validation) {
                    // notify event: inserting - Pending
                    raiseSuccessEvent('inserting', obj);
                    // create the id
                    db_collection.add(obj).then(function (doc_ref) {
                        if (!getValidDocumentId(obj)) { // check if the document id is valid
                            obj = setValidDocumentId(doc_ref.id, obj);
                            console.log("Document written with ID: ", doc_ref.id);
                        }
                        raiseSuccessEvent('inserted', obj); // FIXME: client creates firestore id so we can move bellow
                        // raiseSuccessEvent('stored', obj);
                    })
                        .catch(function (error) {
                            console.error("Error adding document: ", error);
                        });
                    // TODO: Event: inserted - Success
                    // TODO: Event: exists - Failure
                    // TODO: Event: failure - Failure
                } else {
                    // notify event: invalid - Failure validating against schema
                    raiseFailureEvent('invalid', obj, {
                        message: 'Document structure is invalid against schema',
                        code: 501,
                        data: { document: obj, schema: schema, reason: schema_validation }
                    });
                }
            }
        },
        read: {
            schema: {},
            handler: (document_id) => {
                // https://firebase.google.com/docs/firestore/query-data/get-data
                // validate document id

                if (!isValidDocumentId(document_id)) {
                    raiseFailureEvent('invalid', { id: document_id }, { message: 'Document identifier is invalid', code: 301, data: { document_id: document_id } });
                } else {
                    // notify event: reading - Pending
                    raiseSuccessEvent('reading', { id: document_id });
                    const doc_ref = db_collection.doc(document_id);
                    if (doc_ref.exists) {
                        // notify event: read - Success
                        let obj = doc_ref.get()
                        obj = setValidDocumentId(doc_ref.id, obj);
                        try { raiseSuccessEvent('read', obj); }
                        catch (e) { raiseFailureEvent('failure', { id: document_id }, e); }
                    } else {
                        // notify event: missing - Failure
                        raiseFailureEvent('missing', { document_id: document_id }, { message: 'Document identifier is invalid', code: 401, data: { document_id: document_id } });
                    }
                }
            }
        },
        update: {
            schema: {},
            handler: (obj) => {
                // TODO: make sure we dont send 'undefined' data
                // validate obj against schema
                const schema_validation = isValidDocumentSchema(obj, schema);
                if (schema_validation) {
                    // TODO: Event: invalidated - Failure validating against schema
                    // notify event: updating - Pending
                    let document_id = getValidDocumentId(obj);
                    raiseSuccessEvent('updating', obj);
                    if (document_id) {
                        const doc_ref = db_collection.doc(document_id);
                        if (true) {// TODO: handle missing object --- if(doc_ref.exists) { 
                            db_collection.doc(document_id).update(obj).then(function () {
                                // notify event: updated - Success
                                raiseSuccessEvent('updated', obj);
                            })
                                .catch(function (error) {
                                    // notify event: failure - Failure
                                    raiseFailureEvent('failure', obj, error);
                                });
                        } else {
                            // notify event: missing - Failure
                            raiseFailureEvent('missing', obj, {});
                            // TODO: Event: failure - Failure
                        }
                    } else {
                        raiseFailureEvent('invalid', obj, { message: 'Document identifier is invalid', code: 301, data: document_id });
                    }
                } else {

                }
            }
        },
        upsert: {
            schema: {},
            handler: (obj) => {
                // TODO: make sure we dont send 'undefined' data
                // validate document id
                const schema_validation = isValidDocumentSchema(obj, schema);
                if (schema_validation) {
                    //Event: upserting - Pending
                    raiseSuccessEvent('upserting', obj);
                    const document_id = getValidDocumentId(obj);
                    if (document_id) {
                        db_collection.doc(document_id).set(obj, { merge: true }).then(function () {
                            // TODO: Update to version 9. We get the merged object in one call
                            db_collection.doc(document_id).get({ id: obj.id }).then(function (merged_obj) {
                                // Event: upserted - Success, return merged data
                                raiseSuccessEvent('upserted', merged_obj.data());
                            });

                        }).catch(function (error) {
                            // Event: failure - Failure
                            raiseFailureEvent('failure', obj, error);
                        }).then(function (res) {
                            // raiseSuccessEvent('upserted', obj);
                            // debugger;
                        });
                    } else {
                        db_collection.add(obj).then(function (doc_ref) {
                            if (!getValidDocumentId(obj)) { // check if the document id is valid
                                obj = setValidDocumentId(doc_ref.id, obj);
                                console.log("Document written with ID: ", doc_ref.id);
                            }
                            raiseSuccessEvent('upserted', obj); // FIXME: client creates firestore id so we can move bellow
                            // raiseSuccessEvent('stored', obj);
                        })
                            .catch(function (error) {
                                console.error("Error adding document: ", error);
                            });
                    }
                } else {
                    // notify event: invalid - Failure validating against schema
                    raiseFailureEvent('invalid', obj, {
                        message: 'Document structure is invalid against schema',
                        code: 501,
                        data: { document: obj, schema: schema, reason: schema_validation }
                    });
                }
            }
        },
        delete: {
            schema: {},
            handler: (document_id) => {
                // https://firebase.google.com/docs/firestore/manage-data/delete-data
                // validate document id
                document_id = getValidDocumentId(document_id);
                if (document_id) {
                    // Event: deleting - Pending
                    raiseSuccessEvent('deleting', { id: document_id });
                    // Do the actuall deletion
                    if (true) { // TODO: check if this exist
                        db_collection.doc(document_id).delete().then(function () {
                            raiseSuccessEvent('deleted', { id: document_id });
                        }).catch(function (error) {
                            // TODO: Event: missing - Failure
                            // TODO: Event: failure - Failure

                            raiseFailureEvent('failure', { id: document_id }, error);
                        });
                        // Event: deleted - Success
                        // raiseSuccessEvent('deleted', obj);
                    } else {
                        raiseFailureEvent('missing', { id: document_id });
                    }
                } else {
                    raiseFailureEvent('invalid', { id: document_id }, { message: 'Document identifier is invalid', code: 301, data: { document_id: document_id } });
                }
            }
        },
        search: {
            schema: {},
            handler: (data) => {
                /**
                 * Create filters schema requiring {"filter": [{"attribute", "rule", "value"}], "update": true/false}
                 * Must follow rules as defined by: https://firebase.google.com/docs/firestore/query-data/queries
                 */
                // https://firebase.google.com/docs/firestore/query-data/listen
                // https://firebase.google.com/docs/firestore/query-data/get-data
                // TODO: validate filter
                // TODO: Event: searching - Pending
                const filters = data.filters;
                filters.forEach(function (filter) {
                    db_collection.where(filter.attribute, filter.rule, filter.value);
                });
                raiseSuccessEvent('searching', data);
                if (data.update) {
                    db_collection
                        .onSnapshot(function (snapshot) {
                            var docs = [];
                            snapshot.docs.forEach((doc) => {
                                // TODO: Event: updated - Success
                                let obj = doc.data();
                                if (!obj.id) { obj.id = doc.id; }
                                console.log("Firebase:Collection:search:onSnapshot", doc.id, " => ", obj);
                                raiseSuccessEvent('updated', obj);
                                docs.push(obj);
                            });
                            raiseSuccessEvent('searched', docs);
                        });
                } else {
                    db_collection
                        .get()
                        .then(function (queryResult) {
                            var docs = [];
                            queryResult.forEach(function (doc) {
                                // doc.data() is never undefined for query doc snapshots
                                console.log("Firebase:Collection:search:get", doc.id, " => ", doc.data());
                                raiseSuccessEvent('updated', doc.data());
                                docs.push(doc.data());
                            });
                            raiseSuccessEvent('searched', docs);
                        }).catch(function (error) {
                            // TODO: Event: failure - Failure
                            console.warn("Firebase:Collection:search:get", error);
                            raiseFailureEvent('failure', data, error);
                        });
                    // TODO: Event: failure - Failure                    
                }
            }
        }
    }
    );
}