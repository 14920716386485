import React from 'react';
// material ui components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { renderContent } from '../../util/ComponentUtil';
// styles
import { withStyles } from '@material-ui/core/styles';
// DD components
import { AvatarRender, DDAvatarSingle } from '../Avatar/AvatarComponent'

import { ListBase, events as baseEvents, triggers as baseTriggers } from './ListBase'

export const events = baseEvents;
export const triggers = baseTriggers;
export const view = {
  style: {}
};

const style = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
});

function ListAvatar(props) {
  if (!props.avatar) return null;
  return (  // render array of avatars
    <ListItemAvatar>
      <AvatarRender view={props.avatar.view} data={props.avatar.data} />
    </ListItemAvatar>
  )

}

class ListComponent extends ListBase {
  /**
   * Used to manage internal state of avatars
   */
  constructor(props) {
    super(props);
    this.eventDD = this.registerComponent({}, {}, {
      name: "ListComponent",
      author: "Kjartan Jónsson",
      description: "List component",
      version: "0.3.2"
    });
  }

  secondaryAction = (itm, classes) => {
    if (itm.secondary) {
      const action = renderContent(classes, { "content": itm.secondary });
      if (action) {
        return (<ListItemSecondaryAction>{action}</ListItemSecondaryAction>)
      }
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    return (
      <List className={classes.root}>
        {this.state.data.map((itm, idx) =>
        (
          <ListItem
            key={itm.id}
            selected={this.state.selectedIndex === itm.id}
            onClick={(event) => this.handleSelect(itm[idx], itm, idx)}
          >
            <ListAvatar avatar={itm.avatar} />
            <ListItemText primary={itm.title} secondary={itm.subtitle} />
            {this.secondaryAction(itm, classes)}
          </ListItem>
        )
        )}
      </List>
    )
  }
}

export default withStyles(style, { withTheme: true })(ListComponent);
