// https://material-ui.com/components/Page/
// https://www.digitalocean.com/community/tutorials/react-Page-component
import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// material ui components

// styles
import { withStyles } from '@material-ui/core/styles';

import { LayoutBase, events as baseEvents, triggers as baseTriggers } from './LayoutBase'
export const events = baseEvents;
export const triggers = baseTriggers;


const style = (theme) => ({
});

export const view = {
  style: {
    contentMargin: {}
  }
}

class PageComponent extends LayoutBase {
  /**
   * Used to manage internal state of avatars
   */

  static propTypes = {
    //data: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    props.view.style = { ...props.view.style, ...{} }
    props.view.style.contentMargin = props.view.style.contentMargin || 3;
    super(props);

    this.eventDD = this.registerComponent({}, {}, {
      name: "PageComponent",
      author: "Kjartan Jónsson",
      description: "Page component",
      version: "0.3.2"
    });

    this.state = { ...this.state, ...{ selectedId: 0 } };
    // this.history = withRouter();
  }

  handleChange(idx, url, paramters) {
    const state = this.state.data[idx];
    this.setSelectedId(state.id);
    window.location.href = state.url || state.id;
  }

  handleSelectRoute(idx, url, params, query) {
    let paramters = { ...query, ...params };
    // TODO: notify event to all event listeners
    console.info(paramters);
  }

  getJsonFromSearch(search) {
    if (search.length) {
      let url = search;
      let query = url.substr(1);
      let result = {};
      query.split("&").forEach(function (part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
      return result;
    } else {
      return {};
    }
  }

  render() {

    const classes = this.props.classes;
    const view = this.props.view;

    return (
      <Fragment>
        <div className="container-xxl">
          <Router>
            <div>
              <Switch>
                {this.state.data.map((itm, idx) => {

                  let exact_route = (itm.url === "/") ? true : false;

                  return (
                    <Route exact={exact_route} key={"route-" + idx} path={itm.url || itm.id} render={(props) => {
                      document.title = view.style.title || itm.title;
                      this.handleSelectRoute(idx, props.match.path, props.match.params, this.getJsonFromSearch(props.location.search));
                      return this.renderContent(classes, itm);
                    }} />
                  )
                }
                )}
                <Route render={() => { return (<h1>Page not found</h1>) }} />
              </Switch>
            </div>
          </Router>
        </div >
      </Fragment >
    );
  }
}

export default withStyles(style, { withTheme: true })(PageComponent);

export const schema = {
  "$id": "",
  "$schema": "https://json-schema.org/draft/2020-12/schema",
  description: "",
  type: "object",
  properties: {
    title: {
      type: "string"
    },
    description: {
      type: "string"
    }
  }
}