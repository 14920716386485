import React from 'react';

import ImageComponent from './ImageComponent';

// layout manager
import LayoutManager from '../Layout/Manager'

export function Image(props) {
    // lets enumerate schema to extract uiSchema and validation
    return (<ImageComponent {...props} />);
}

////////////////////////////////////////////////


export function registerImage() {
    // self register component to layout manager
    LayoutManager.getInstance().registerComponent({
        component: Image,
        type: LayoutManager.TYPE.image
    });
}
