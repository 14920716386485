import { Component } from 'react';

// common tools
import { findItemIndexById, removeItemIndexByIndex } from '../../util/ObjUtil';
// event handler
import EventManager from '../../event/Event';
import { mergeObjects } from 'react-jsonschema-form/lib/utils';

export const triggers = () => {
  return {
    submit: {
      alias: [],
      info: {
        name: 'Submit',
        description: 'Submit entire list'
      },
      schema: {}
    },
    replace: {
      alias: [],
      info: {
        name: 'Replace',
        description: 'Replace data'
      },
      schema: {}
    },
    push: {
      alias: [],
      info: {
        name: 'Push',
        description: 'Adds data at the end to component'
      },
      schema: {}
    },
    push_front: {
      alias: [],
      info: {
        name: 'Push front',
        description: 'Adds data to the front of the component'
      },
      schema: {}
    },
    delete: {
      alias: [],
      info: {
        name: 'Delete data instance',
        description: 'Removes data from the component'
      },
      schema: {}
    },
    pop: {
      alias: [],
      info: {
        name: 'Pop back item',
        description: 'Deletes / removes back data item'
      },
      schema: {}
    },
    pop_front: {
      alias: [],
      info: {
        name: 'Pop front item',
        description: 'Deletes / removes front data item'
      },
      schema: {}
    },
    select: {
      alias: [],
      info: {
        name: 'Select item',
        description: 'Selects the data item'
      },
      schema: {}
    },
    clear: {
      alias: [],
      info: {
        name: 'Clear items',
        description: 'Removes all items from list'
      },
      schema: {}
    }
  }
}

export const events = () => {
  return {
    changed: {
      alias: [],
      info: {
        name: 'changed',
        description: 'Changed size'
      },
      schema: {}
    },
    replacing: {
      alias: [],
      info: {
        name: 'replacing',
        description: 'Replacing content'
      },
      schema: {}
    },
    replaced: {
      alias: [],
      info: {
        name: 'replaced',
        description: 'Replace content'
      },
      schema: {}
    },
    submitted: {
      alias: [],
      info: {
        name: 'Submitted',
        description: 'Subbited all items in list'
      },
      schema: {}
    },
    deleted: {
      alias: [],
      info: {
        name: 'Deleted',
        description: 'Deleted specified items'
      },
      schema: {}
    },
    pushed: {
      alias: [],
      info: {
        name: 'Pushed',
        description: 'Pushed item in front of list'
      },
      schema: {}
    },
    selected: {
      alias: [],
      info: {
        name: 'Selected',
        description: 'Selecting item'
      },
      schema: {}
    },
    deselected: {
      alias: [],
      info: {
        name: 'De-Selected',
        description: 'Unselecting item'
      },
      schema: {}
    },
    clearing: {
      alias: [],
      info: {
        name: 'Clearing',
        description: 'Removing all items'
      },
      schema: {}
    },
    cleared: {
      alias: [],
      info: {
        name: 'Cleared',
        description: 'Removed all items'
      },
      schema: {}
    }
  }
}

export class ListBase extends Component {
  /**
   * Used to manage internal state of avatars
   */
  constructor(props) {
    super(props);
    this.props = props;
    // ensure array
    let _data = [];
    if (props.data) {
      if (Array.isArray(props.data)) {
        _data = props.data;
      } else {
        // check if this is an empty object
        if (typeof (props.data) == 'object') {
          // objects should not be empty
          if (props.data.length) { _data = [props.data]; }
        } else {
          _data = [props.data];
        }
      }
    }
    // apply default values
    this.state = { data: _data || [], selectedIndex: 0, selectedId: null };
  }

  registerComponent = (actionHandlers, eventHandlers, component_info) => {
    actionHandlers = actionHandlers || {};
    eventHandlers = eventHandlers || {};
    // add our known handlers
    const dataActionHandlers = {
      submit: {
        schema: {},
        handler: (objs) => { // submit
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['submitted'].id, { count: this.state.data.length, items: this.state.data }, {});
        }
      },
      replace: {
        schema: {},
        handler: (objs) => { // append
          const data_state = this.state.data || [];
          // want an array
          if (!Array.isArray(objs)) { objs = [objs]; }
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['replacing'].id, { count: data_state.length, old: data_state, new: objs }, {});
          let data = objs;
          this.setState({ ...this.state, data: data });
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['replaced'].id, { count: data.length, items: data }, {});
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['changed'].id, { count: data.length, items: data }, {});
        }
      },
      push: {
        schema: {},
        handler: (objs) => { // append
          const data_added = [];
          const data_state = this.state.data || [];
          // want an array
          if (!Array.isArray(objs)) { objs = [objs]; }
          objs.forEach(obj => {
            const idx = findItemIndexById(obj.id, data_state);
            if (idx === null) {
              data_added.push(obj);
            } else { // update the index
              data_state[idx] = mergeObjects(data_state[idx], obj); // TODO: fetch from utils
            }
          });
          let data = [...data_state, ...data_added];
          this.setState({ ...this.state, data: data });
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['pushed'].id, { count: data.length, items: data }, {});
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['changed'].id, { count: data.length, items: data }, {});
        }
      },
      push_front: {
        schema: {},
        handler: (objs) => {
          const data_added = [];
          const data_state = this.state.data || [];
          // want an array
          if (!Array.isArray(objs)) { objs = [objs]; }
          objs.forEach(obj => {
            const idx = findItemIndexById(obj.id, this.state.data);
            if (idx === null) {
              data_added.push(obj);
            } else { // update the index
              data_state[idx] = obj;
            }
          });
          let data = [...data_added, ...data_state];
          this.setState({ ...this.state, data: data });
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['changed'].id, { count: data.length, items: data }, {});
        }
      },
      delete: {
        schema: {},
        handler: (objs) => {
          // want an array
          if (!Array.isArray(objs)) { objs = [objs]; }
          let data = this.state.data;
          const deleting = [];
          objs.forEach(obj => {
            if (obj.id) {
              // requires array
              let idx = findItemIndexById(obj.id, data);
              if (idx !== null) {
                deleting.push(obj.id);
                data = removeItemIndexByIndex(idx, data)
                this.setState({ ...this.state, data: data });
              }
            }
          });
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['changed'].id, { count: data.length, items: data, deleted: deleting }, {});
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['deleted'].id, { count: deleting.length, items: data, deleted: deleting }, {});
        }
      },
      pop: {
        schema: {},
        handler: (obj) => {
          if (this.state.data.length > 0) {
            this.state.data.splice(this.state.data.length - 1, 1)
            this.setState({ ...this.state, data: this.state.data });
            EventManager.getInstance().addEvent(this.props.id, this.eventDD['changed'].id, { count: this.state.data.length, items: this.state.data }, {});
          }
        }
      },
      pop_front: {
        schema: {},
        handler: (obj) => {
          this.state.data.splice(0, 1);
          this.setState({ ...this.state, data: this.state.data });
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['changed'].id, { count: this.state.data.length, items: this.state.data }, {});
        }
      },
      select: {
        schema: {},
        handler: (objs) => {
          // want an array
          if (!Array.isArray(objs)) { objs = [objs]; }
          objs.forEach(obj => {
            if (obj.id) {
              this.setSelectedId(obj.id);
            }
          });
        }
      },
      clear: {
        schema: {},
        handler: (obj) => {
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['clearing'].id, { count: this.state.data.length, items: this.state.data }, {});
          this.setState({ ...this.state, data: [] });
          EventManager.getInstance().addEvent(this.props.id, this.eventDD['cleared'].id, { count: this.state.data.length, items: this.state.data }, {});
        }
      }
    }

    // register componenet overiding or adding new event handlers
    this.ddEvent = EventManager.getInstance().register(this.props.id, { ...dataActionHandlers, ...actionHandlers }, { ...events(), ...eventHandlers }, component_info);
    return this.ddEvent;
  }

  getData = () => {
    return this.state.data;
  }

  setSelectedId = (id) => {
    let selectedIdx = 0;
    let selectedId = undefined; //this.state.data[selectedIdx].id;
    this.state.data.forEach((itm, idx, array) => {
      if (id === itm.id) { selectedId = itm.id; selectedIdx = idx; }
    });
    this.setState({ ...this.state, ...{ selectedIndex: selectedIdx, selectedId: selectedId } })
  }

  handleSelect = (key, data, index, evt) => {
    if (!evt) { this.setSelectedId(data.id); }
    EventManager.getInstance().addEvent(this.props.id, this.eventDD['selected'].id, { id: data.id }, evt);
    /*
    if (data.id === this.state.selectedId){
      this.setSelectedId(-1);
      EventManager.getInstance().addEvent(this.props.id, this.eventDD['deselected'].id, {id: data.id}, evt);
    }else{
      if (!evt){this.setSelectedId(index);}
      EventManager.getInstance().addEvent(this.props.id, this.eventDD['selected'].id,  {id: data.id}, evt);
    }
    */
  }

  render() { return null; }
}

export const schema = {
  '$id': 'https://example.com/list.schema.json',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  'description': 'List item',
  'type': 'array',
  'items': {
    '$ref': 'list.item.json'
  }
};

export const item = {
  '$id': 'https://example.com/list.schema.json',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  'description': 'List item',
  'type': 'object',
  'required': ['text'],
  'properties': {
    'text': {
      '$ref': 'list.itemtext.json'
    },
    'avatar': {
      '$ref': 'avatar.schema.json'
    },
    'action': {
      'oneOf': [
        { '$ref': 'button.schema.json' },
        { '$ref': 'list.itemtext.json' },
        // {'$ref': 'form.checkbox.json'}
        // {'$ref': 'form.switch.json'}
      ]
    }
  }
};

export const itemtext = {
  '$id': 'https://example.com/list.itemtext.json',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  'description': 'List item text',
  'type': 'object',
  'properties': {
    'title': {
      'type': 'string'
    },
    'subtitle': {
      'type': 'string'
    }
  }
};
