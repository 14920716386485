// https://material-ui.com/api/typography/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { ListBase, events as baseEvents, triggers as baseTriggers } from '../List/ListBase'

export const events = baseEvents;
export const triggers = baseTriggers;
export const view = {
  style: {
    color: {}
  }
};

const style = theme => ({
});

class TextComponent extends ListBase {

  constructor(props) {
    if (!props.view.style) { props.view.style = {} }
    super(props);

    this.eventDD = this.registerComponent({}, {}, {
      name: "TextComponent",
      author: "Kjartan Jónsson",
      description: "Text component",
      version: "0.3.2"
    });
    /*
    heading = h1
      section = h2
        part = h3
          chapters = h4
            title = h5
              subtitle = h6
                caption = subtitle
                  subtitle = subtitle2
                    description = body1
                      text = body2
    */

    this.configs = {
      heading: { variant: 'h1', component: 'h1' },
      section: { variant: 'h2', component: 'h2' },
      part: { variant: 'h3', component: 'h3' },
      chapter: { variant: 'h4', component: 'h4' },
      title: { variant: 'h5', component: 'h5' },
      subtitle: { variant: 'h6', component: 'h6' },
      caption: { variant: 'subtitle', component: 'p' },
      summary: { variant: 'subtitle2', component: 'p' },
      description: { variant: 'body1', component: 'p' },
      text: { variant: 'body2', component: 'p' }
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.data.map((itm, idx) => {
          const config = this.configs[itm.typography];
          return (
            <Typography key={this.props.id + idx} variant={config.variant} color={this.props.view.style.color} component={config.component}>
              {itm.text}
            </Typography>
          )
        })
        }
      </React.Fragment>
    )
  }
}

export default withStyles(style, { withTheme: true })(TextComponent);
/*
const input_schema = {
  "$id": "https://example.com/address.schema.json",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "description": "Text Component",
  "type": "object",
  "properties": {
    "id": {
      "type": "string"
    },
    "typography": {
      "type": "string" // one of
    },
    "text": {
      "type": "string"
    }
  }
}
*/
