// https://material-ui.com/api/typography/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';

import { ListBase, events as baseEvents, triggers as baseTriggers } from '../List/ListBase'

export const events = baseEvents;
export const triggers = baseTriggers;
export const view = {
  style: {
    color: {}
  }
};

const style = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
    height: 450,
  }
});

const itemData = [
  {
    img: 'https://material-ui.com/static/images/image-list/breakfast.jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    img: 'https://material-ui.com/static/images/image-list/burgers.jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    img: 'https://material-ui.com/static/images/image-list/honey.jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    img: 'https://material-ui.com/static/images/image-list/vegetables.jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
];

class ImageComponent extends ListBase {

  constructor(props) {
    if (!props.view.style) { props.view.style = {} }
    super(props);

    this.eventDD = this.registerComponent({}, {}, {
      name: "ImageComponent",
      author: "Kjartan Jónsson",
      description: "Image component",
      version: "0.3.2"
    });
  }

  render() {
    const classes = style;

    return (
      <div className={classes.root}>
        <ImageList rowHeight={360} className={classes.imageList} cols={3}>
          {itemData.map((item) => (
            <ImageListItem key={item.img} cols={item.cols || 1}>
              <img src={item.img} alt={item.title} />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    );
  }
}

export default withStyles(style, { withTheme: true })(ImageComponent);
/*
const input_schema = {
  "$id": "https://example.com/address.schema.json",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "description": "Image Component",
  "type": "object",
  "properties": {
    "id": {
      "type": "string"
    },
    "typography": {
      "type": "string" // one of
    },
    "Image": {
      "type": "string"
    }
  }
}
*/
