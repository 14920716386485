import React from 'react';

import { DDCMMNViewerComponent } from './CMMNViewer'
import { DDCMMNEditorComponent } from './CMMNEditor'
// layout manager
import LayoutManager from '../../components/Layout/Manager'

export default function DDCMMNViewer(props) {
  // lets enumerate schema to extract uiSchema and validation
  return (<DDCMMNViewerComponent {...props} />);
}

export function DDCMMNEditor(props) {
  // lets enumerate schema to extract uiSchema and validation
  return (<DDCMMNEditorComponent {...props} />);
}

export function registerViewer() {
  // self register component to layout manager
  LayoutManager.getInstance().registerComponent({
    component: DDCMMNViewer,
    type: 'cmmn-viewer'
  });
}

export function registerEditor() {
  // self register component to layout manager
  LayoutManager.getInstance().registerComponent({
    component: DDCMMNEditor,
    type: 'cmmn-editor'
  });
}
