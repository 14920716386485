import React from 'react';

import ButtonComponent from './ButtonComponent'

// layout manager
import LayoutManager from '../Layout/Manager'

export function Button(props) {
  // lets enumerate schema to extract uiSchema and validation
  return (<ButtonComponent {...props} />);
}

export function registerButton() {
  // self register component to layout manager
  LayoutManager.getInstance().registerComponent({
    component: Button,
    type: LayoutManager.TYPE.button
  });
}

